import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBInput,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBFile,
  MDBSwitch,
} from "mdb-react-ui-kit";
import Select from "react-select";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Auth from "./auth";

import Charts from "./Charts";
const MainDashboard = () => {
  Auth();
  const [viewers, setViewers] = useState([]);
  const [alljobs, setalljobs] = useState([]);
  const [reg, setreg] = useState([]);
  const [earnings, setallearnings] = useState([]);

  const [pendingjobs, setpendingjobs] = useState();
  const [rejectedjobs, setrejected] = useState();

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/getall/getallapproved`,
        {}
      );
      setViewers(response.data.data);
      setviewer(response.data.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchdata1 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getAllviewersWeekly`,
        {}
      );

      setreg(response.data);
      console.log("Successreppoo:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchassignedalljobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/getallassignedjobs
        `,
        {}
      );
      setalljobs(response.data.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchpendingapprovaljobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/pendingapproval`
      );
      console.log("Pending", response.data);
      setpendingjobs(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchrejected = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getrejectedjobs`
      );
      console.log("rejectd", response.data);
      setrejected(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchdata();
    fetchassignedalljobs();
    fetchpendingapprovaljobs();
    fetchdata1();
    fetchrejected();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };
  const [selectedViewer, setSelectedViewer] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  useEffect(() => {
    const toggleValue = localStorage.getItem("toggle");
    const viewerJSON = localStorage.getItem("viewer");

    if (toggleValue === "true" && viewerJSON) {
      setBasicModal(true);
      const viewer = JSON.parse(viewerJSON);
      setSelectedViewer(viewer);
    } else {
      setBasicModal(false);
    }
  }, []);

  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);

  const [filteredviewer, setviewer] = useState(viewers);

  const [currentjobs, setcurrent] = useState();
  const fetchcurrent = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/currentjobs`
      );
      console.log("currentjobs", response.data);
      setcurrent(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchcurrent();
  }, []);

  const [seekers, setseekers] = useState([]);
  const [packages, setpackages] = useState([]);
  const [average, setaverage] = useState([]);

  const fetchdata4 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/getall`,
        {}
      );
      setseekers(response.data.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchdata5 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getallpackages`,
        {}
      );
      setpackages(response.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchdata6 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/averageratings`,
        {}
      );
      setaverage(response.data.averageJobRatings);
      console.log("Successratings:", response.data.averageJobRatings);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatAverage = (avg) => {
    if (avg !== null && !isNaN(parseFloat(avg))) {
      return parseFloat(avg).toFixed(2);
    }
    return "N/A";
  };

  const [weeklypackages, setweeklypackages] = useState();
  const [weeklyearnings, setweeklyearnings] = useState();

  const fetchdata7 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getallpackagesweekly`,
        {}
      );
      setweeklypackages(response.data);
      console.log("Successgetallweekl:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchdata8 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getallearningsweekly`,
        {}
      );
      setweeklyearnings(response.data[0]);
      console.log("Successearnins:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchdata9 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getallearnings`,
        {}
      );
      const total = response.data.reduce(
        (acc, viewer) => acc + viewer.totalEarnings,
        0
      );
      setallearnings(total);

      console.log("seucess all ernings:", total);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchdata4();
    fetchdata5();
    fetchdata6();
    fetchdata7();
    fetchdata8();
    fetchdata9();
  }, []);

  return (
    <>
      <Lognavbar />
      <div>
        <h1 style={{ color: "#ff4e00" }}>Admin Dashboard</h1>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            Viewers Registrations{" "}
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Registrations
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>{reg ? reg.length : "0"}</h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>
        <center>
          <h2 style={{ color: "#ff4e00" }}>Viewers Accounts </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Viewers
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {viewers && viewers.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Available
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {viewers &&
                      viewers.filter((viewer) => viewer.availability).length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Un Available
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {viewers &&
                      viewers.filter((viewer) => viewer.availability === 0)
                        .length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Assigned Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {alljobs && alljobs.assignedJobsCount}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Unassigned Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {alljobs && alljobs.unassignedJobsCount}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            {" "}
            Pending Approval
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Pending Approval
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {pendingjobs ? pendingjobs.length : "0"}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Need to be Assigned
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {alljobs ? alljobs.unassignedJobsCount : "0"}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Rejected Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {rejectedjobs ? rejectedjobs.length : "0"}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            Current Jobs
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {currentjobs ? currentjobs.length : "0"}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            Seekers Accounts
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Seekers
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {seekers && seekers.length}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            Packages Bought
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Packages
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {packages && packages.length}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            Seekers Ratings
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Ratings
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>{formatAverage(average)}</h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <h2 className="mt-5" style={{ color: "#ff4e00" }}>
            Finance Portal
          </h2>

          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Earnings This Week
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    £ {weeklyearnings && weeklyearnings.totalEarnedAmount}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Packages Bought This Week
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {weeklypackages && weeklypackages.length}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Packages Bought All Time
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {packages && packages.length}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Earnings All Time
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                  £ {earnings ? Number(earnings).toFixed(1) : "N/A"}
                  </h4>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>
      </div>

      <Charts />

      <Footer />
    </>
  );
};

export default MainDashboard;
