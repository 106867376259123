import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBInput,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBFile,
  MDBSwitch,
} from "mdb-react-ui-kit";
import Select from "react-select";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Auth from "./auth";
const ViewerAccount = () => {
  Auth();
  const [viewers, setViewers] = useState([]);
  const [alljobs, setalljobs] = useState([]);
  const [selectedViewers, setSelectedViewers] = useState([]);
  const [selectedViewersChecked, setSelectedViewersChecked] = useState([]);

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/getall/getallapproved`,
        {}
      );
      setViewers(response.data.data);
      setviewer(response.data.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchassignedalljobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/getallassignedjobs
        `,
        {}
      );
      setalljobs(response.data.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchdata();
    fetchassignedalljobs();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };
  const [selectedViewer, setSelectedViewer] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  useEffect(() => {
    const toggleValue = localStorage.getItem("toggle");
    const viewerJSON = localStorage.getItem("viewer");

    if (toggleValue === "true" && viewerJSON) {
      setBasicModal(true);
      const viewer = JSON.parse(viewerJSON);
      setSelectedViewer(viewer);
    } else {
      setBasicModal(false);
    }
  }, []);

  const toggleOpen = (viewer) => {
    console.log("opening");
    const newModalState = !basicModal;
    localStorage.setItem("toggle", newModalState);
    if (!newModalState) {
      localStorage.removeItem("viewer");
    } else {
      const viewerJSON = JSON.stringify(viewer);
      localStorage.setItem("viewer", viewerJSON);
    }
    setSelectedViewer(viewer);
    setBasicModal(newModalState);
  };

  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);

  const [filteredviewer, setviewer] = useState(viewers);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterviewer(event.target.value);
  };

  const filterviewer = (query) => {
    const filtered = viewers.filter((viewer) => {
      return (
        viewer.firstname.toLowerCase().includes(query.toLowerCase()) ||
        viewer.lastname.toLowerCase().includes(query.toLowerCase()) ||
        viewer.email.toLowerCase().includes(query.toLowerCase()) ||
        (viewer.availability === 1 ? "Yes" : "No")
          .toString()
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        viewer.ratings.toString().toLowerCase().includes(query.toLowerCase())
      );
    });
    setviewer(filtered);
  };

  const [basicModalcreate, setBasicModalcreate] = useState(false);

  const toggleOpencreate = () => setBasicModalcreate(!basicModalcreate);

  const [isAvailable, setIsAvailable] = useState(false);

  const handleToggle = async (viewer) => {
    console.log(viewer);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/makeViewerAvailable`,
        {
          isAvailable: !isAvailable,
          viewersId: viewer,
        }
      );

      console.log("Toggle Success:", response.data);

      setIsAvailable(!isAvailable);

      setSelectedViewer({
        ...selectedViewer,
        availability: !isAvailable,
      });
    } catch (error) {
      console.error("Toggle Error:", error);
    }
  };

  const handlesuspension = async (viewer, suspension) => {
    console.log("handle suspension", viewer, suspension);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/suspendViewer`,
        {
          suspension: suspension,
          viewersId: viewer,
        }
      );

      console.log("Toggle Success:", response.data);

      fetchdata();
      fetchassignedalljobs();
      setBasicModal(false);
    } catch (error) {
      console.error("Toggle Error:", error);
    }
  };
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    gender: "",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    postCode: "",
    country: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/createviewerbyadmin`,
        formData
      );
      console.log("Success:", response.data);

      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        gender: "",
        street1: "",
        street2: "",
        street3: "",
        city: "",
        postCode: "",
        country: "",
        password: "",
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    console.log(formData);
  };

  const [basicModal5, setBasicModal5] = useState(false);

  const toggleOpen5 = () => setBasicModal5(!basicModal5);

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedavailable, setselectedavailable] = useState(null);
  const [selectedratings, setselectedratings] = useState(null);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedsuspension, setselectedsuspension] = useState("");

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption ? selectedOption.value : "");
  };

  const handleDateChange = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
  };

  const handleAvailability = (selectedOption) => {
    setselectedavailable(selectedOption ? selectedOption.value : "");
  };
  const handleRatings = (event) => {
    setselectedratings(event.target.value);
  };

  const handlesuspensionfilter = (selectedOption) => {
    setselectedsuspension(selectedOption ? selectedOption.value : "");
  };

  const filterViewer = () => {
    const filtered = viewers.filter((viewer) => {
      console.log("I am in filtering ", selectedCity, selectedDate);
      const viewerDate = new Date(viewer.createdAt).toISOString().split("T")[0];

      const cityMatch =
        !selectedCity ||
        viewer.city.toLowerCase().includes(selectedCity.toLowerCase());
      const availabilityMatch =
        !selectedavailable ||
        (viewer.availability === 1 ? "Yes" : "No")
          .toString()
          .toLowerCase()
          .includes(selectedavailable.toLowerCase());
      const ratingsMatch =
        !selectedratings ||
        viewer.ratings
          .toString()
          .toLowerCase()
          .includes(selectedratings.toLowerCase());
      const dateMatch = !selectedDate || viewerDate === selectedDate;

      const suspensedmatch = !selectedsuspension ||  (viewer.suspension === "No" ? "No" : "Yes")
      .toString()
      .toLowerCase()
      .includes(selectedsuspension.toLowerCase());;

      
      return cityMatch && availabilityMatch && ratingsMatch && dateMatch && suspensedmatch;
    });

    setviewer(filtered);
    toggleOpen5();
  };

  const [page, setPage] = useState(1);
  const itemsPerPage = 50;

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleDelete = async () => {
    if(window.confirm('It will delete all earnings, packages on which viewers is working, Chat related to view. Are you sure?')){
      try {
        console.log(selectedViewersChecked);
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/Admin/delviewers`,
          {
            data: { viewerIds: selectedViewersChecked },
          }
        );

        const remainingViewers = viewers.filter(
          (viewer) => !selectedViewers.includes(viewer)
        );
        setViewers(remainingViewers);
        setSelectedViewers([]);
      } catch (error) {
        console.error("Delete Error:", error);
      }
      fetchdata();
      window.location.href="/viewers-account";
    }
  };

  const handleSelectAll = () => {
    if (selectedViewers.length > 0) {
      setSelectedViewers([]);
      setSelectedViewersChecked([]);
    } else {
      setSelectedViewers([...viewers]);
      setSelectedViewersChecked(viewers.map((viewer) => viewer.viewersId));
    }
  };

  return (
    <>
      <Lognavbar />
      <div>
        <h1 style={{ color: "#ff4e00" }}>Viewers Accounts</h1>

        <center>
          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Viewers
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {viewers && viewers.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Available
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {viewers &&
                      viewers.filter((viewer) => viewer.availability).length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Un Available
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {viewers &&
                      viewers.filter((viewer) => viewer.availability === 0)
                        .length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Assigned Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {alljobs && alljobs.assignedJobsCount}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Unassigned Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {alljobs && alljobs.unassignedJobsCount}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <div className="">
          <div className="search  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />

            <MDBBtn className="mt-3" onClick={toggleOpen5}>
              filter
            </MDBBtn>
          </div>

          <MDBModal
            className="modalRegister"
            open={basicModal5}
            onClose={() => {
              setBasicModal5(false);
            }}

            tabIndex="-1"
            responsive
            
          >
            <MDBModalDialog scrollable>
              <MDBModalContent className="bg-dark">
                <MDBModalHeader>
                  <MDBModalTitle>Filter Viewers</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleOpen5}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="mb-3">
                    <label htmlFor="citySelect" className="form-label">
                      City
                    </label>

                    <Select
                      options={viewers.map((viewer) => ({
                        value: viewer.city,
                        label: viewer.city,
                      }))}
                      styles={customStyles}
                      placeholder="Choose your city"
                      onChange={handleCityChange}
                    />
                    <label htmlFor="datePicker" className="form-label mt-3">
                      Date
                    </label>
                    <MDBInput
                      id="datePicker"
                      type="date"
                      name="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="form-control"
                    />

                    <label htmlFor="dateSelect" className="form-label mt-3">
                      Availability
                    </label>
                    <Select
                      options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                      ]}
                      styles={customStyles}
                      placeholder="Choose"
                      onChange={handleAvailability}
                    />

                    <label htmlFor="dateSelect" className="form-label mt-3">
                      Suspended
                    </label>
                    <Select
                      options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                      ]}
                      styles={customStyles}
                      placeholder="Choose"
                      onChange={handlesuspensionfilter}
                    />

                    <label htmlFor="dateSelect" className="form-label mt-3">
                      Rating
                    </label>
                    <MDBInput
                      id="datePicker"
                      type="text"
                      value={selectedratings}
                      onChange={handleRatings}
                      className="form-control"
                    />
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={toggleOpen5}>
                    Close
                  </MDBBtn>
                  <MDBBtn onClick={filterViewer}>Save changes</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

          <MDBTable
            style={{ width: "95%" }}
            className="mx-auto mt-3"
            responsive
          >
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th>
                  {" "}
                  <MDBIcon
                    style={{ cursor: "pointer" }}
                    onClick={toggleOpencreate}
                    fas
                    icon="plus-circle"
                  />
                </th>
                <th>Viewer ID</th>

                <th>Name</th>
                <th>Available</th>
                <th>Suspended</th>
                <th>Rating</th>
                <th>Details</th>
                <th>Delete</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="text-white">
              {filteredviewer &&
                filteredviewer
                  .slice(startIndex, endIndex)
                  .map((viewer, index) => (
                    <tr key={viewer.Id}>
                      <td></td>
                      <td>{viewer.viewersId}</td>

                      <td>
                        <div className="ms-3">
                          <p className="fw-bold mb-1">
                            {viewer.firstname} {viewer.lastname}
                          </p>
                          <p className="text-muted mb-0">{viewer.email}</p>
                        </div>
                      </td>

                      <td>{viewer.availability === 0 ? "No" : "Yes"}</td>
                      <td>{viewer.suspension === "No" ? "No" : "Yes"}</td>

                      <td>
                        {viewer.ratings > 0
                          ? Array.from({ length: viewer.ratings }).map(
                              (_, index) => (
                                <MDBIcon
                                  key={index}
                                  style={{ color: "#ff4e00" }}
                                  icon="star"
                                />
                              )
                            )
                          : "0"}
                      </td>

                      <td>
                        <MDBBtn
                          color="info"
                          size="sm"
                          onClick={() => toggleOpen(viewer)}
                        >
                          View Details
                        </MDBBtn>
                      </td>

                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedViewers([...selectedViewers, viewer]);
                              setSelectedViewersChecked([
                                ...selectedViewersChecked,
                                viewer.viewersId,
                              ]);
                            } else {
                              setSelectedViewers(
                                selectedViewers.filter(
                                  (v) => v.viewersId !== viewer.viewersId
                                )
                              );
                              setSelectedViewersChecked(
                                selectedViewersChecked.filter(
                                  (id) => id !== viewer.viewersId
                                )
                              );
                            }
                          }}
                          checked={selectedViewersChecked.includes(
                            viewer.viewersId
                          )}
                        />
                      </td>
                    </tr>
                  ))}
            </MDBTableBody>
          </MDBTable>
        </div>
        <div className="d-flex justify-content-around  my-3">
          <MDBBtn onClick={prevPage} disabled={page === 1}>
            Prev
          </MDBBtn>
          <MDBBtn
            onClick={nextPage}
            disabled={
              (endIndex >= filteredviewer && filteredviewer.length) ||
              (filteredviewer && filteredviewer.length < 50)
            }
          >
            Next
          </MDBBtn>
        </div>

        {selectedViewersChecked.length !== 0 ? (
          <MDBBtn onClick={handleDelete}>Delete Selected</MDBBtn>
        ) : (
          ""
        )}

        <MDBBtn className="ms-2" onClick={handleSelectAll}>
          {selectedViewersChecked.length === 0
            ? "Select All"
            : "UnSelect All"}
        </MDBBtn>


        <MDBModal
          className="modalRegister"
          open={basicModal}
          onClose={() => setBasicModal(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Viewer Details</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {selectedViewer && (
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/files/${selectedViewer.profilePicture}`}
                        className="img-fluid w-75"
                        alt=""
                      />
                    </div>

                    <div className="col-md-8 text-start">
                      <p className="fw-bold mb-1">
                        {selectedViewer.firstname}
                        {selectedViewer.lastname}
                      </p>
                      <p className="text-muted mb-0">{selectedViewer.email}</p>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Gender :{" "}
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.gender}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          DOB :{" "}
                        </span>
                        <span className=" fw-bold">{selectedViewer.dob}</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Mobile :
                        </span>
                        <span className="fw-bold">{selectedViewer.mobile}</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Street 1 :{" "}
                        </span>
                        <span className="fw-bold">
                          {selectedViewer.street1}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 2 :{" "}
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.street2}
                        </span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 3 :{" "}
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.street3}
                        </span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          City :
                        </span>
                        <span className=" fw-bold">
                          {" "}
                          {selectedViewer.city}{" "}
                        </span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25  fw-bolder"
                        >
                          Postcode:
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.postcode}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Country :
                        </span>
                        <span className="fw-bold">
                          {selectedViewer.country}
                        </span>
                      </div>

                      <div>
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder"
                        >
                          About
                        </span>
                        <p className=" fw-bold text-justify">
                          {selectedViewer.about}
                        </p>
                      </div>

                      <Link
                        to={`/all-jobs/${selectedViewer.viewersId}`}
                        className="text-decoration-none"
                      >
                        <MDBBtn className="w-100 my-2 btn">View Jobs</MDBBtn>
                      </Link>
                      <MDBSwitch
                        id="flexSwitchCheckDefault"
                        label={
                          selectedViewer.availability
                            ? "Make Unavailable"
                            : "Make Available"
                        }
                        onChange={() => {
                          handleToggle(selectedViewer.viewersId);
                        }}
                        checked={selectedViewer.availability}
                      />
                      {selectedViewer.suspension !== "No" ? (
                        <MDBBtn
                          color="danger"
                          onClick={() =>
                            handlesuspension(selectedViewer.viewersId, "No")
                          }
                        >
                          Unsuspend
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          color="success"
                          onClick={() =>
                            handlesuspension(selectedViewer.viewersId, "Yes")
                          }
                        >
                          Suspend
                        </MDBBtn>
                      )}
                    </div>
                  </div>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal
          className="modalRegister"
          open={basicModalcreate}
          onClose={() => setBasicModalcreate(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Create Viewer</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpencreate}
                ></MDBBtn>
              </MDBModalHeader>

              <form onSubmit={handleSubmit}>
                <MDBModalBody>
                  <MDBInput
                    className="mb-3"
                    label="First Name"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Last Name"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <Select
                    className="mb-3"
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                    ]}
                    styles={customStyles}
                    placeholder="Select Gender"
                    name="gender"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      setFormData({
                        ...formData,
                        gender: selectedOption.value,
                      });
                    }}
                  />

                  <MDBInput
                    className="mb-3"
                    label="Street / House No"
                    name="street1"
                    value={formData.street1}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Street2"
                    name="street2"
                    value={formData.street2}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Street3"
                    name="street3"
                    value={formData.street3}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Post Code"
                    name="postCode"
                    value={formData.postCode}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    type="text"
                  />
                  <MDBInput
                    className="mb-3"
                    label="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    type="password"
                  />
                  <MDBBtn type="submit">Submit</MDBBtn>
                </MDBModalBody>
              </form>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpencreate}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>

      <Footer />
    </>
  );
};

export default ViewerAccount;
