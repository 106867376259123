import React, { useEffect, useState } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput
  } from 'mdb-react-ui-kit';
  import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Lognavbar from '../Components/lognavbar';
import Footer from './footer';

export default function Earnings() {
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  useEffect(()=>{
    document.body.style.backgroundColor="#000000";
  },[])

  return (
    <div className='about'>
        <Lognavbar />
        <div className='head'>
            <h1 className='mainheading' style={{color:"#ff4e00",marginTop:"2%"}}>Earnings</h1>
            <center>
            <MDBRow className='row-cols-1 row-cols-md-4 g-4' style={{width:"80%"}}>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Jobs Done</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>10</h4>
                        <MDBCardText>
          
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Total Earning</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>$100</h4>
                        <MDBCardText>


                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>Total Paid</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>$40</h4>
                        <MDBCardText>


                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className='h-100' style={{backgroundColor:"#1a2229"}}>
                    <MDBCardBody>
                        <MDBCardTitle style={{marginTop:"25px",color:"white"}}>This Week Earning (5%)</MDBCardTitle>
                        <h4 style={{color:"#ff4e00"}}>$30</h4>
                        <MDBCardText>


                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                </MDBRow>
            
                <MDBBtn style={{color:"white",backgroundColor:"#ff4e00",marginTop:"30px"}} onClick={toggleOpen}>Withdraw</MDBBtn>
            </center>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog >
                    <MDBModalContent style={{backgroundColor:"black"}}>
                        <MDBModalHeader>
                        <MDBModalTitle style={{color:"#ff4e00"}}>Withdraw</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                        <div className="mb-4" style={{marginTop:"10px"}}>
                            <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Amount</label>
                            <MDBInput size='lg' id='form1' type='number' style={{borderRadius:"0"}}/>
                        </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={toggleOpen}>
                            Close
                        </MDBBtn>
                        <MDBBtn style={{backgroundColor:"#ff4e00",color:"white"}}>Submit</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        <div style={{textAlign:"left",marginLeft:"60px",marginTop:"20px"}}>
          <h4 style={{color:"white"}}>Earnings History</h4>
          <MDBTable style={{width:"95%"}}>
              <MDBTableHead style={{backgroundColor:"#1a2229",color:"#ff4e00"}}>
                <tr>
                  <th scope='col'>Sr</th>
                  <th scope='col'>Job Name</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Dated</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody style={{color:"white"}}>
                <tr>
                  <th scope='row'>1</th>
                  <td>ABC</td>
                  <td>$100</td>
                  <td>Earning</td>
                  <td>20-03-2024</td>
                </tr>
                <tr>
                  <th scope='row'>1</th>
                  <td>ABC</td>
                  <td>$100</td>
                  <td>Withdraw</td>
                  <td>20-03-2024</td>
                </tr>
              </MDBTableBody>
          </MDBTable>
        </div>
        </div>
        <Footer/>
    </div>
  )
}
