import React, { useState, useEffect } from "react";
import { MDBInput, MDBCol, MDBRow, MDBBtn,MDBTableBody,MDBTableHead,MDBTable } from "mdb-react-ui-kit";
import Select from "react-select";
import axios from "axios";
import Footer from "./footer";
import Lognavbar from "../Components/lognavbar";
import Auth from "./auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreatePromoCode = () => {
  Auth();
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [promocodes, setPromoCodes] = useState("");

  const fetchpromos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getpromos`
      );
      console.log("promocodes", response.data);
      setPromoCodes(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    document.body.style.color = "white";
    document.body.style.backgroundColor = "black";
    fetchpromos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const promoCodeData = {
      promoCode,
      discount,
      package: selectedPackage ? selectedPackage.value : null,
    };

    console.log("Submitting Promo Code Data:", promoCodeData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/createpromocode`,
        promoCodeData
      );
      console.log("Success:", response.data);
      toast.success("Promo Code Created Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });

      setPromoCode("");
      setDiscount("");
      setSelectedPackage(null);

      fetchpromos();

    } catch (error) {
      console.error("Error:", error);
      toast.error("Error Creating Promo Code", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };


  const deletePromocode=async(promoId)=>{

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/deletepromo/${promoId}`
      );
      toast.success("Promo Code Deleted Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      fetchpromos();

   
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error Deleting Promo Code", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

  }
  return (
    <>
      <Lognavbar />
      <ToastContainer />

      <div className="container subadminform">
        <div className="row w-100 justify-content-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <h2
                style={{ color: "#ff4e00", marginTop: "2%" }}
                className="mb-4 text-center"
              >
                Create Promo Code
              </h2>

              <Select
                className="mb-3 basic-single-select"
                classNamePrefix="select"
                name="package"
                value={selectedPackage}
                styles={customStyles}
                onChange={setSelectedPackage}
                options={[
                  { label: "Basic Viewing", value: "Basic Viewing" },
                  { label: "Property Search", value: "Property Search" },
                  { label: "Pro Viewing", value: "Pro Viewing" },
                ]}
                placeholder="Select Package"
              />
              <MDBRow className="mb-4">
                <MDBCol>
                  <MDBInput
                    id="promoCode"
                    label="Promo Code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    id="discount"
                    label="Discount (%)"
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>

              <MDBBtn type="submit" className="mb-4" block>
                Create Promo Code
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
      <h2
        style={{ color: "#ff4e00", marginTop: "2%" }}
        className="mb-4 text-center"
      >
        All Promo Codes
      </h2>


      <MDBTable responsive className="mx-auto" style={{ width: "96%" }}>
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th scope="col">Package Name</th>

                <th scope="col">Promo Code </th>
                <th scope="col">Discount(%)</th>
                <th scope="col">Action</th>

              </tr>
            </MDBTableHead>
            <MDBTableBody style={{ color: "white" }}>
              {promocodes &&
                promocodes.map((promo) => {
                  return (
                    <tr>
                      <td>{promo.PackageName}</td>

                      <td>
                        {promo.PromoCode}
                      </td>

                      <td>
                        {promo.Discount}
                      </td>
                      <td>
                        <MDBBtn
                          onClick={() => {
                           deletePromocode(promo.PromoCodeID)
                          }}
                          style={{ backgroundColor: "#fe400" }}
                        >
                          Delete
                        </MDBBtn>
                      </td>

                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>

      <Footer />
    </>
  );
};

export default CreatePromoCode;
