import React, { useState, useEffect } from "react";
import { MDBInput, MDBCol, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import Select from "react-select";
import axios from "axios";
import Footer from "./footer";
import Lognavbar from "../Components/lognavbar";
import Auth from "./auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CreateSubAdmin = () => {
  Auth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pagesAccess, setPagesAccess] = useState([]);

  useEffect(() => {
    document.body.style.color = "white";
    document.body.style.backgroundColor = "black";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const subAdminData = {
      firstName,
      lastName,
      email,
      password,
      pagesAccess: pagesAccess.map((option) => option.value),
    };

    console.log("Submitting Sub-Admin Data:", subAdminData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/createsubadmin`,
        subAdminData
      );
      console.log("Success:", response.data);
      toast.success("Login Successful", {
        position: toast.POSITION.TOP_CENTER,
      });

      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setPagesAccess("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Lognavbar />
      <ToastContainer />

      <div className="container subadminform">
        <div className="row w-100 justify-content-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <h2
                style={{ color: "#ff4e00", marginTop: "2%" }}
                className="mb-4 text-center"
              >
                Create Sub-Admin
              </h2>
              <MDBRow className="mb-4">
                <MDBCol>
                  <MDBInput
                    id="form3Example1"
                    label="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    id="form3Example2"
                    label="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBInput
                className="mb-4"
                type="email"
                id="form3Example3"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                className="mb-4"
                type="password"
                id="form3Example4"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Select
                className="mb-3 basic-multi-select"
                classNamePrefix="select"
                isMulti
                name="pagesAccess"
                value={pagesAccess}
                onChange={setPagesAccess}
                options={[
                  { label: "Main Page", value: "Main Page" },
                  { label: "VIEWERS PORTAL", value: "VIEWERS PORTAL" },
                  { label: "SEEKERS PORTAL", value: "SEEKERS PORTAL" },
                  { label: "Sub-Admin", value: "Sub-Admin" },
                  { label: "FINANCE PORTAL", value: "FINANCE PORTAL" },
                  { label: "MAIN DASHBOARD", value: "MAIN DASHBOARD" },

                ]}
                placeholder="Select Page"
              />

              <MDBBtn type="submit" className="mb-4" block>
                Create Sub-Admin
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateSubAdmin;
