import React, { useState } from "react";
import { useEffect } from "react";

import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
  MDBFile,
  MDBBtn,
  MDBIcon,
  MDBTextArea,
  MDBInput,
} from "mdb-react-ui-kit";
import axios from "axios";
import Select from "react-select";
import Footer from "./footer";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import Auth from "./auth";

import Lognavbar from "../Components/lognavbar";
const MainPageUpdates = () => {

  Auth();
  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };

  const [images, setImages] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newImages = selectedFiles.map((file) => URL.createObjectURL(file));
    setFiles([...files, ...selectedFiles]);
    setImages([...images, ...newImages]);
    setDescriptions([...descriptions, ...selectedFiles.map(() => "")]);
  };

  const handleDescriptionChange = (index, newDescription) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = newDescription;
    setDescriptions(newDescriptions);
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newDescriptions = [...descriptions];
    newDescriptions.splice(index, 1);
    setDescriptions(newDescriptions);

    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
      formData.append("descriptions", descriptions[index]);
    });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/slideshow1`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response from server:", response.data);
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  //slideshow2
  const [images1, setImages1] = useState([]);
  const [descriptions1, setDescriptions1] = useState([]);
  const [files1, setFiles1] = useState([]);

  const handleFileChange1 = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newImages = selectedFiles.map((file) => URL.createObjectURL(file));
    setFiles1([...files1, ...selectedFiles]);
    setImages1([...images1, ...newImages]);
    setDescriptions1([...descriptions1, ...selectedFiles.map(() => "")]);
  };

  const handleDescriptionChange1 = (index, newDescription) => {
    const newDescriptions = [...descriptions1];
    newDescriptions[index] = newDescription;
    setDescriptions1(newDescriptions);
  };

  const removeImage1 = (index) => {
    const newImages = [...images1];
    newImages.splice(index, 1);
    setImages1(newImages);

    const newDescriptions = [...descriptions1];
    newDescriptions.splice(index, 1);
    setDescriptions1(newDescriptions);

    const newFiles = [...files1];
    newFiles.splice(index, 1);
    setFiles1(newFiles);
  };

  const handleUpload1 = async () => {
    const formData = new FormData();
    files1.forEach((file, index) => {
      formData.append("files", file);
      formData.append("descriptions", descriptions1[index]);
    });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/slideshow2`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response from server:", response.data);
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [descriptionpackage, setDescriptionpackage] = useState("");

  const handlePackageChange = (selectedOption) => {
    setSelectedPackage(selectedOption);
  };

  const handleDescriptionChange2 = (e) => {
    setDescriptionpackage(e.target.value);
  };

  const handleSavePackage = async () => {
    if (!selectedPackage || !descriptionpackage) {
      alert("Please select a package and enter a description.");
      return;
    }

    const packageData = {
      name: selectedPackage.value,
      description: descriptionpackage,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/packagedescription`,
        packageData
      );
      console.log("Response from server:", response.data);
      alert("Package saved successfully");
    } catch (error) {
      console.error("Error sending data to server:", error);
      alert("Error saving package");
    }
  };

  const [viewingType, setViewingType] = useState(null);
  const [description3, setDescription3] = useState("");

  const handleViewingTypeChange = (selectedOption) => {
    setViewingType(selectedOption);
  };

  const handleDescriptionChange3 = (e) => {
    setDescription3(e.target.value);
  };

  const handleSaveDescription = async () => {
    if (!viewingType || !description3) {
      alert("Please select a viewing type and enter a description.");
      return;
    }

    const jobDescriptionData = {
      name: viewingType.value,
      description: description3,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/becomeaviewerjob`,
        jobDescriptionData
      );
      console.log("Response from server:", response.data);
      alert("Job description saved successfully");
    } catch (error) {
      console.error("Error saving job description:", error);
      alert("Error saving job description");
    }
  };

  //Faqs

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleAdd = async () => {
    if (!question || !answer) {
      alert("Please enter a question and an answer.");
      return;
    }

    const faqData = {
      question: question,
      answer: answer,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Admin/faqs`, faqData);
      console.log("Response from server:", response.data);
      alert("FAQ saved successfully");
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error saving FAQ:", error);
      alert("Error saving FAQ");
    }
  };
  return (
    <>
      <Lognavbar />
      <div className="container">
        <h1 style={{ color: "#fe4e00" }} className="mb-4">
          Main Page Updates
        </h1>

        <MDBRow className="justify-content-lg-between flex-lg-row flex-column">
          <MDBCol>
            <h4 className="text-start mt-4">SlideShow</h4>
            <p style={{color:"#ff4e00",textAlign:"left"}}>Image Dimension should be: 710x450px</p>
            <MDBCarousel showControls fade key={images.length}>
              {images.map((path, index) => (
                <MDBCarouselItem key={index + 1} itemId={index + 1}>
                  <img
                    src={path}
                    className="d-block  imae  w-100"
                    alt={`Slide ${index + 1}`}
                  />
                </MDBCarouselItem>
              ))}
            </MDBCarousel>

            <div className="mt-3 images">
              <div className="d-flex w-100 justify-content-between flex-wrap">
                {images.map((path, index) => (
                  <div
                    key={index}
                    className="position-relative  imageoutside w-sm-50 mb-3"
                  >
                    <img
                      className="img-fluid imagesinside"
                      src={path}
                      alt={`Image ${index}`}
                    />
                    <MDBIcon
                      onClick={() => removeImage(index)}
                      className="crossicon"
                      fas
                      icon="times"
                      style={{ position: "absolute", top: "5px", right: "5px" }}
                    />
                  </div>
                ))}
              </div>
              {/* {images.length > 3 && (
                <div className="d-flex flex-wrap justify-content-between mt-3">
                  {images.slice(3).map((path, index) => (
                    <div key={index + 3} className="w-100 w-sm-50 mb-3">
                      <img
                        className="img-fluid w-100"
                        src={path}
                        alt={`Image ${index + 3}`}
                      />
                      <MDBIcon
                        onClick={() => removeImage(index)}
                        className="crossicon"
                        fas
                        icon="times"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              )} */}
            </div>

            <MDBFile
              multiple
              className="bg-black text-light mt-3"
              label=""
              id="customFile"
              onChange={handleFileChange}
            />

            {images.map((path, index) => (
              <MDBTextArea
                key={index}
                label={`Description for Image ${index + 1}`}
                className="my-3"
                rows="2"
                placeholder="Describe Your Image"
                value={descriptions[index]}
                onChange={(e) => handleDescriptionChange(index, e.target.value)}
              />
            ))}

            <MDBBtn className="w-100 mt-3" onClick={handleUpload}>
              Upload
            </MDBBtn>
          </MDBCol>

          <MDBCol>
            <h4 className="text-start mt-4">SlideShow</h4>
            <p style={{color:"#ff4e00",textAlign:"left"}}>Image Dimension should be: 710x450px</p>
            <MDBCarousel showControls fade key={images1.length}>
              {images1.map((path, index) => (
                <MDBCarouselItem key={index + 1} itemId={index + 1}>
                  <img
                    src={path}
                    className="d-block imae w-100"
                    alt={`Slide ${index + 1}`}
                  />
                </MDBCarouselItem>
              ))}
            </MDBCarousel>

            <div className="mt-3 images">
              <div className="d-flex w-100 justify-content-between  flex-wrap">
                {images1.map((path, index) => (
                  <div
                    key={index}
                    className="position-relative imageoutside w-sm-50 mb-3"
                  >
                    <img
                      className="img-fluid imagesinside"
                      src={path}
                      alt={`Image ${index}`}
                    />
                    <MDBIcon
                      onClick={() => removeImage1(index)}
                      className="crossicon"
                      fas
                      icon="times"
                      style={{ position: "absolute", top: "5px", right: "5px" }}
                    />
                  </div>
                ))}
              </div>
              {/* {images1.length > 3 && (
                <div className="d-flex flex-wrap justify-content-between mt-3">
                  {images1.slice(3).map((path, index) => (
                    <div key={index + 3} className="w-100 w-sm-50 mb-3">
                      <img
                        className="img-fluid w-100"
                        src={path}
                        alt={`Image ${index + 3}`}
                      />
                      <MDBIcon
                        onClick={() => removeImage1(index)}
                        className="crossicon"
                        fas
                        icon="times"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              )} */}
            </div>

            <MDBFile
              multiple
              className="bg-black text-light mt-3"
              label=""
              id="customFile"
              onChange={handleFileChange1}
            />

            {images1.map((path, index) => (
              <MDBTextArea
                key={index}
                label={`Description for Image ${index + 1}`}
                className="my-3"
                rows="2"
                placeholder="Describe Your Image"
                value={descriptions1[index]}
                onChange={(e) =>
                  handleDescriptionChange1(index, e.target.value)
                }
              />
            ))}

            <MDBBtn className="w-100 mt-3" onClick={handleUpload1}>
              Upload
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        <MDBRow className="justify-content-lg-between my-4 flex-lg-row flex-column">
          <MDBCol>
            <h4 className="text-start">Packages Description</h4>

            <Select
              options={[
                { label: "Basic Viewing", value: "Basic Viewing" },
                { label: "Pro Viewing", value: "Pro Viewing" },
                { label: "Property Search", value: "Property Search" },
              ]}
              styles={customStyles}
              placeholder="Select Package"
              onChange={handlePackageChange}
            />

            <MDBTextArea
              label=""
              className="my-3"
              id="textAreaExample"
              rows="6"
              placeholder="Describe Your Package"
              value={descriptionpackage}
              onChange={handleDescriptionChange2}
            />

            <MDBBtn className="mt-3" onClick={handleSavePackage}>
              Save Package
            </MDBBtn>
          </MDBCol>

          <MDBCol>
            <h4 className="text-start mt-lg-0 mt-4">Become a Viewer Job Description</h4>
            <Select
              options={[
                { label: "Basic Viewing", value: "Basic Viewing" },
                { label: "Pro Viewing", value: "Pro Viewing" }
              ]}
              styles={customStyles}
              placeholder="Select Viewing"
              onChange={handleViewingTypeChange}
            />

            <MDBTextArea
              label=""
              className="my-3"
              id="textAreaExample"
              rows="6"
              placeholder="Describe Your Job"
              value={description3}
              onChange={handleDescriptionChange3}
            />

            <MDBBtn className="mt-3" onClick={handleSaveDescription}>
              Save Job Description
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        <MDBRow className="justify-content-lg-between my-4 flex-lg-row flex-column">
          <h4 className="text-start">Frequently Asked Questions</h4>
          <MDBCol md={12} lg={6}>
            <MDBInput
              value={question}
              onChange={handleQuestionChange}
              label="Question"
              id="formControlDefault"
              type="text"
            />
            <MDBTextArea
              style={{ color: "white" }}
              value={answer}
              onChange={handleAnswerChange}
              label="Answer"
              className="my-3"
              id="textAreaExample"
              rows="6"
            />
            <MDBBtn onClick={handleAdd} className="w-100">
              Add
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </div>
      <Footer />
    </>
  );
};

export default MainPageUpdates;
