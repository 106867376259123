import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";
import Auth from "./auth";
import axios from "axios";
import Select from "react-select";

export default function CurrentJobs() {
  Auth();
  const [basicActive, setBasicActive] = useState("tab1");
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal1, setBasicModal1] = useState(false);
  const [currentjobs, setcurrent] = useState([]);
  const [filtereddummyData, setdummyData] = useState();
  const [selectedjob, setselectedjob] = useState([]);

  const toggleOpen = (job) => {
    setBasicModal(!basicModal);
    setselectedjob(job);
  };
  const toggleOpen1 = () => setBasicModal1(!basicModal1);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  const fetchcurrent = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/currentjobs`
      );
      console.log("currentjobs", response.data);
      setcurrent(response.data.data);
      setdummyData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchcurrent();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterdummyData(event.target.value);
  };

  const filterdummyData = (query) => {
    const filtered =
      currentjobs &&
      currentjobs.filter((dummyData) => {
        console.log("Tis is the dummy daa", dummyData);


        console.log( "dummy",dummyData.Id.toString())
        return (
          (dummyData.packagename || '').toLowerCase().includes(query.toLowerCase()) ||
          (dummyData.Q2specify || '').toLowerCase().includes(query.toLowerCase()) ||
          (dummyData.firstname || '').toLowerCase().includes(query.toLowerCase()) ||
          (dummyData.lastname || '').toLowerCase().includes(query.toLowerCase()) ||
          (dummyData.Id !== undefined && dummyData.Id.toString() === query) ||
          (dummyData.Id+1000==query)
          );

      });

    setdummyData(filtered);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  
  const [basicModal5, setBasicModal5] = useState(false);

  const toggleOpen5 = () => setBasicModal5(!basicModal5);

  const [selectedCity, setSelectedCity] = useState("");

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption ? selectedOption.value : "");
  };

  const filterViewer = () => {
    const filtered =
      currentjobs &&
      currentjobs.filter((dummyData) => {
        console.log("I am in filtering ", dummyData);

        const cityMatch =
          !selectedCity ||
          dummyData.city.toLowerCase().includes(selectedCity.toLowerCase());

        return cityMatch;
      });

    setdummyData(filtered);
    toggleOpen5();
  };


  
  const [page, setPage] = useState(1);
  const itemsPerPage = 28;

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  // Function to handle pagination
  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };


  return (
    <div className="about">
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          Current Jobs
        </h1>

        <center>
          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {currentjobs && currentjobs.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>
        <center>
          <div className="search mb-3  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />

            <MDBBtn className="mt-3" onClick={toggleOpen5}>
              filter
            </MDBBtn>
          </div>

          <MDBModal
            className="modalRegister"
            open={basicModal5}
            onClose={() => {
              setBasicModal5(false);
            }}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent className="bg-dark">
                <MDBModalHeader>
                  <MDBModalTitle>Modal title</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleOpen5}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="mb-3">
                    <label htmlFor="citySelect" className="form-label">
                      City
                    </label>

                    <Select
                      options={currentjobs.map((viewer) => ({
                        value: viewer.city,
                        label: viewer.city,
                      }))}
                      styles={customStyles}
                      placeholder="Choose your city"
                      onChange={handleCityChange}
                    />
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={toggleOpen5}>
                    Close
                  </MDBBtn>
                  <MDBBtn onClick={filterViewer}>Save changes</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <>
            <MDBRow
              className="row-cols-1 row-cols-md-4 g-4"
              style={{ margin: "30px" }}
            >
              {filtereddummyData &&
                filtereddummyData.slice(startIndex, endIndex).map((jobs) => {
                  return (
                    <MDBCol>
                      <MDBCard
                        className="h-100"
                        style={{ backgroundColor: "#1a2229" }}
                      >
                       
                        <MDBCardBody>
                          <MDBCardTitle
                            style={{ marginTop: "25px", color: "white" }}
                          >
                            {jobs.packagename}
                          </MDBCardTitle>
                         
                          <MDBCardText>
                            <MDBBtn
                              style={{
                                color: "white",
                                backgroundColor: "#ff4e00",
                              }}
                              className="mx-2 my-2"
                              onClick={() => {
                                toggleOpen(jobs);
                              }}
                            >
                              Details
                            </MDBBtn>
                          </MDBCardText>

                          <h5
                            style={{ color: "#ff4e00" }}
                            className="card-title"
                          >
                            Assigned To
                          </h5>
                          <div className="assigned-to-details">
                            <MDBCardText className="assigned-to-name text-white">
                              <span style={{ color: "#ff4e00" }}>Name</span>:{" "}
                              {jobs.firstname} {jobs.lastname}
                            </MDBCardText>
                            <MDBCardText className="assigned-to-email text-white">
                              <span style={{ color: "#ff4e00" }}>Ref No: </span>
                              {jobs.Id+1000}
                            </MDBCardText>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  );
                })}
            </MDBRow>

            <div className="d-flex justify-content-around mt-3">
                <MDBBtn onClick={prevPage} disabled={page === 1}>
                  Prev
                </MDBBtn>
                <MDBBtn onClick={nextPage} disabled={(endIndex >= filtereddummyData&&filtereddummyData.length)||(filtereddummyData&&filtereddummyData.length<=itemsPerPage)}>
                  Next
                </MDBBtn>
              </div>
          </>
        </center>
      </div>

      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Job Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <MDBRow>
                <MDBCol md={12}>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    {selectedjob && selectedjob.packagename}
                  </MDBCardTitle>
                  <p
                    style={{
                      color: "#ff4e00",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    £{selectedjob && selectedjob.paid}
                  </p>

                  {selectedjob &&
                    selectedjob.packagename === "Basic Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          The property can be found here,{" "}
                          <strong>
                            {selectedjob.Q1URL
                              ?  <a href={selectedjob.Q1URL} target="_blank" rel="noopener noreferrer">
                                    {selectedjob.Q1URL}
                                  </a>
                              : selectedjob.street1
                              ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                              : "N/A"}
                          </strong>
                          . They{" "}
                          <strong>
                            {selectedjob.Q3
                              ? selectedjob.Q3 === "yes"
                                ? "have"
                                : "have not"
                              : "N/A"}
                          </strong>{" "}
                          communicated with agent/property management. We can
                          reach them on{" "}
                          <strong>
                            {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                          </strong>
                          , and what they said was{" "}
                          <strong>
                            {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                          </strong>
                          . They are{" "}
                          <strong>
                            {selectedjob.Q4
                              ? selectedjob.Q4 === "yes"
                                ? "okay"
                                : "not okay"
                              : "N/A"}
                          </strong>{" "}
                          with 10 working days to get viewing done.{" "}
                          {selectedjob.Q4 === "no" ? (
                            <span>
                              They want it done in{" "}
                              <strong>{selectedjob.Q4Date}</strong>.
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>
                          They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "email" ? (
                            <strong> on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                          . The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}

                  {selectedjob && selectedjob.packagename === "Pro Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedjob.Q1URL
                            ?  <a href={selectedjob.Q1URL} target="_blank" rel="noopener noreferrer">
                                {selectedjob.Q1URL}
                              </a>
                            : selectedjob.street1
                            ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                            : "N/A"}
                        </strong>{" "}
                        They{" "}
                        <strong>
                          {selectedjob.Q2
                            ? selectedjob.Q2 === "yes"
                              ? "have specific concerns about the property"
                              : "have not specific concerns about the property"
                            : "N/A"}
                        </strong>{" "}
                        They are concerned about
                        <strong>
                          {selectedjob.Q2 === "yes"
                            ? `  ${selectedjob.Q2specify}`
                            : ""}
                        </strong>{" "}
                        They
                        <strong>
                          {selectedjob.Q3
                            ? selectedjob.Q3 === "yes"
                              ? "  have"
                              : "  have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can
                        reach them on{" "}
                        <strong>
                          {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedjob.Q4
                            ? selectedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedjob.Q5 === "email" ? (
                          <strong>on {selectedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedjob.promo ? selectedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedjob &&
                    selectedjob.packagename !== "Basic Viewing" &&
                    selectedjob.packagename !== "Pro Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          They plan on living at{" "}
                          <strong>
                            {selectedjob.Q1 ? selectedjob.Q1 : "N/A"}
                          </strong>
                          . They plan on{" "}
                          <strong>
                            {selectedjob.Q6 ? selectedjob.Q6 : "N/A"}
                          </strong>
                          .
                        </p>

                        {selectedjob.Q6 === "Renting" && (
                          <p>
                            If it's renting; Their allocated budget for this
                            property is{" "}
                            <strong>
                              {selectedjob.Q2 ? selectedjob.Q2 : "N/A"}
                            </strong>
                            . They are looking to rent for{" "}
                            <strong>
                              {selectedjob.Q3 ? selectedjob.Q3 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        {selectedjob.Q6 === "Buying" && (
                          <p>
                            They want a property within the price range of{" "}
                            <strong>
                              {" "}
                              £{selectedjob.Q7 ? selectedjob.Q7 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        <p>
                          They are looking for a property with these
                          preferences:{" "}
                          <strong>
                            {selectedjob.Q4 ? selectedjob.Q4 : "N/A"}
                          </strong>
                          . They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "Email" ? (
                            <strong>on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                        </p>

                        <p>
                          The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                onClick={toggleOpen}
                style={{ color: "white", backgroundColor: "green" }}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        className="assignjobmodal"
        open={basicModal1}
        onClose={() => setBasicModal1(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle>Select dummyData for Assignment</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen1}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>...</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen1}>
                Close
              </MDBBtn>
              <MDBBtn onClick={toggleOpen1}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Footer />
    </div>
  );
}
