import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,

  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBIcon,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Lognavbar from "../Components/lognavbar";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";
import Auth from "./auth";
import Select from "react-select";


export default function History() {
  Auth();
  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  const [jobhistory, setjobhistory] = useState([]);
  const [filteredviewer, setviewer] = useState();

  const fetchjobhistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/jobhistory`
      );
      console.log("completed", response.data);
      setjobhistory(response.data.data);
      setviewer(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchjobhistory();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterviewer(event.target.value);
  };

  const filterviewer = (query) => {
    const filtered =
      jobhistory &&
      jobhistory.filter((viewer) => {
        return viewer.firstname.toLowerCase().includes(query.toLowerCase()) ||
          viewer.lastname.toLowerCase().includes(query.toLowerCase()) ||
          viewer.packagename.toLowerCase().includes(query.toLowerCase()) ||
          viewer.Q2specify
          ? viewer.Q2specify.toLowerCase().includes(query.toLowerCase())
          : false ||
              viewer.jobratings
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              viewer.Id
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase());
      });
    setviewer(filtered);
  };

  const NotifyNew = () => {
    toast.error("Chat Not Exists", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };
  const downloadChat = async (viewersId, seekersId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat/downloadchat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ viewersId, seekersId }),
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          NotifyNew();
        }
        throw new Error("Network response was not ok");
      }

      console.log(response);

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `chat_${viewersId}_${seekersId}.txt`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const [basicModal5, setBasicModal5] = useState(false);

  const toggleOpen5 = () => setBasicModal5(!basicModal5);

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedavailable, setselectedavailable] = useState(null);
  const [selectedratings, setselectedratings] = useState(null);

  const [selectedDate, setSelectedDate] = useState("");

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption ? selectedOption.value : "");
  };

  const handleDateChange = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
  };

  const handleAvailability = (selectedOption) => {
    setselectedavailable(selectedOption ? selectedOption.value : "");
  };
  const handleRatings = (event) => {
    setselectedratings(event.target.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }

  const filterViewer = () => {
    const filtered = jobhistory.filter((viewer) => {
      console.log("I am in filtering ", selectedCity, selectedDate);
  
      const cityMatch = !selectedCity || viewer.city.toLowerCase().includes(selectedCity.toLowerCase());
      const ratingsMatch = !selectedratings || viewer.ratings.toString().toLowerCase().includes(selectedratings.toLowerCase());
      const dateMatch = !selectedDate || viewer.createdAt === selectedDate;
  
      return cityMatch  && ratingsMatch && dateMatch;
    });
  
    setviewer(filtered);
    toggleOpen5();
  };
  
  return (
    <div className="about">
      <ToastContainer />
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          Job History
        </h1>

        <div style={{ marginTop: "5%" }}>
          <div className="search mb-3  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />

            <MDBBtn className="mt-3" onClick={toggleOpen5}>
              filter
            </MDBBtn>
          </div>

          
          <MDBModal
            className="modalRegister"
            open={basicModal5}
            onClose={() => {
              setBasicModal5(false);
            }}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent className="bg-dark">
                <MDBModalHeader>
                  <MDBModalTitle>Modal title</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleOpen5}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="mb-3">
                    <label htmlFor="citySelect" className="form-label">
                      City
                    </label>

                    <Select
                      options={jobhistory&&jobhistory.map((viewer) => ({
                        value: viewer.city,
                        label: viewer.city,
                      }))}
                      styles={customStyles}
                      placeholder="Choose your city"
                      onChange={handleCityChange}
                    />
                  <label htmlFor="datePicker" className="form-label mt-3">
                      Date
                    </label>
                    <MDBInput
                      id="datePicker"
                      type="date"
                      name="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="form-control"
                    />

                 

                    <label htmlFor="dateSelect" className="form-label mt-3">
                      Rating
                    </label>
                    <MDBInput
                      id="datePicker"
                      type="text"
                      value={selectedratings}
                      onChange={handleRatings}
                      className="form-control"
                    />
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={toggleOpen5}>
                    Close
                  </MDBBtn>
                  <MDBBtn onClick={filterViewer}>Save changes</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBTable responsive className="mx-auto" style={{ width: "96%" }}>
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th scope="col">Job Name</th>

                <th scope="col">Job Ref Number</th>
                <th scope="col">Viewer Name</th>
                <th scope="col">Dated</th>

                <th scope="col">Download Chats</th>
                <th scope="col">Rating</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody style={{ color: "white" }}>
              {filteredviewer &&
                filteredviewer.map((viewer) => {
                  return (
                    <tr>
                      <td>{viewer.packagename}</td>
                      <td>{viewer.Id}</td>

                      <td>
                        {viewer.firstname} {viewer.lastname}
                      </td>
                      <td>
                        {convertToUKTime(viewer.completedAt)}
                      </td>
                      <td>
                        <MDBBtn
                          onClick={() => {
                            downloadChat(viewer.viewersId, viewer.seekersId);
                          }}
                          style={{ backgroundColor: "#fe400" }}
                        >
                          Download Chat
                        </MDBBtn>
                      </td>

                      <td>
                        {viewer.jobratings > 0?
                          Array.from({ length: viewer.jobratings }).map(
                            (_, index) => (
                              <MDBIcon
                                key={index}
                                style={{ color: "#ff4e00" }}
                                icon="star"
                              />
                            )
                          ):"0"}
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>

      <Footer />
    </div>
  );
}
