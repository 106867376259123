import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBtn,
  MDBInput,
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";
import Select from "react-select";
import Auth from "./auth";
import axios from "axios";

export default function PendingJobs() {
  Auth();
  const [basicActive, setBasicActive] = useState("tab1");
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal1, setBasicModal1] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [selectedjob, setselectedjob] = useState(null);
  const [jobs, setjobs] = useState([]);
  const [viewers, setviewers] = useState([]);
  const [sortedViews, setSortedViews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedViewer, setSelectedViewer] = useState(null);
  const [pendingfilter, setdummyData] = useState([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [pendingjobs, setpendingjobs] = useState([]);
  const [rejectedjobs, setrejected] = useState([]);

  const toggleOpen = (job) => {
    setselectedjob(job);
    setBasicModal(!basicModal);
  };

  const [city, setcity] = useState("");
  const [userPostalCode, setpostalcode] = useState("");
  const toggleOpen1 = (job) => {
    setselectedjob(job);

    setcity(job.city);
    setpostalcode(job.postcode);
    setBasicModal1(!basicModal1);
  };
  const toggleOpen2 = (job) => {
    setBasicModal2(!basicModal2);
    setselectedjob(job);
  };

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  const fetchjobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/needtobeassigned`
      );
      setjobs(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchpendingapprovaljobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/pendingapproval`
      );
      setpendingjobs(response.data.data);
      setdummyData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchrejected = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/rejected`
      );
      setrejected(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchviewers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/getall/getallapproved`
      );

      const finalviewers = await response.data.data.filter(
        (viewer) => viewer.suspension !== "Yes"
      );

      setviewers(finalviewers);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchjobs();
    fetchviewers();
    fetchpendingapprovaljobs();
    fetchrejected();
  }, []);

  const filterViewers = (viewers, city) => {
    console.log(viewers,city);
    if (viewers) {
      return viewers.filter(
        (viewer) =>
          viewer.city == city && viewer.availability && !viewer.assignedjob
      );
    }
    return [];
  };

  // const sortViewersByPostalCode = (viewers, userPostalCode) => {
  //   console.log("viewers", viewers, "userPostalCode", userPostalCode);
    
  //   const extractParts = (postcode) => {
  //     const parts = postcode.match(/^([A-Z]+)(\d+)\s?(\d+)?/);
  //     return {
  //       area: parts[1],
  //       district: parseInt(parts[2], 10),
  //       sector: parts[3] ? parseInt(parts[3], 10) : null,
  //     };
  //   };
  
  //   const calculatePostalCodeSimilarity = (postalCodeA, postalCodeB) => {
  //     const a = extractParts(postalCodeA);
  //     const b = extractParts(postalCodeB);
  
  //     if (a.area !== b.area) {
  //       return -1; // Different areas, less similar
  //     }
  
  //     let similarity = 0;
  
  //     // Same area
  //     similarity += 2; // Base similarity for same area
  
  //     // Same district
  //     if (a.district === b.district) {
  //       similarity += 2;
  //     } else {
  //       similarity += 1 / (Math.abs(a.district - b.district) + 1);
  //     }
  
  //     // Same sector (if exists)
  //     if (a.sector !== null && b.sector !== null) {
  //       if (a.sector === b.sector) {
  //         similarity += 1;
  //       } else {
  //         similarity += 1 / (Math.abs(a.sector - b.sector) + 1);
  //       }
  //     }
  
  //     return similarity;
  //   };
  
  //   if (viewers) {
  //     return viewers.sort((a, b) => {
  //       const aPostalCode = a.postcode;
  //       const bPostalCode = b.postcode;
  //       const aSimilarity = calculatePostalCodeSimilarity(aPostalCode, userPostalCode);
  //       const bSimilarity = calculatePostalCodeSimilarity(bPostalCode, userPostalCode);
  
  //       if (bSimilarity !== aSimilarity) {
  //         return bSimilarity - aSimilarity;
  //       }
  //       if (a.availability !== b.availability) {
  //         return a.availability ? -1 : 1;
  //       }
  //       if (a.assignedjob !== b.assignedjob) {
  //         return a.assignedjob ? 1 : -1;
  //       }
  //       return a.firstname.localeCompare(b.firstname);
  //     });
  //   }
  //   return [];
  // };

  useEffect(() => {
    if (viewers.length > 0) {
      let filteredViewers = filterViewers(viewers, city);
      if (filteredViewers.length === 0) {
        filteredViewers = viewers.filter(
          (viewer) => viewer.city === city && viewer.availability
        );
      }
      // const sortedViewers = sortViewersByPostalCode(
      //   filteredViewers,
      //   userPostalCode
      // );
      setSortedViews(filteredViewers);
    }
  }, [city, userPostalCode]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAssignViewer = async (viewer) => {
    setSelectedViewer(viewer);
  };

  const handleassign = async () => {
    const email = viewers.find(
      (viewer) => viewer.viewersId === selectedViewer.value
    )?.email;

    const firstname = viewers.find(
      (viewer) => viewer.viewersId === selectedViewer.value
    )?.firstname;
    const lastname = viewers.find(
      (viewer) => viewer.viewersId === selectedViewer.value
    )?.lastname;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/assign`,
        {
          viewersId: selectedViewer.value,
          job_id: selectedjob.Id,
          email: email,
          firstname: firstname,
          lastname: lastname,
        }
      );
      console.log("Viewer assigned successfully:", response.data);
    } catch (error) {
      console.error("Error assigning viewer:", error);
    }

    toggleOpen2();

    fetchjobs();
  };
  const filteredViewers = viewers.filter((viewer) =>
    viewer.firstname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  const handleSearchChange1 = (event) => {
    setSearchQuery1(event.target.value);
    filterdummyData(event.target.value);
  };

  const filterdummyData = (query) => {
    const filtered = pendingjobs.filter((dummyData) => {
      return (
        dummyData.packagename.toLowerCase().includes(query.toLowerCase()) ||
        (dummyData.Q2specify
          ? dummyData.Q2specify.toLowerCase().includes(query.toLowerCase())
          : false) || dummyData.Id+1000==query
      );
    });
    setdummyData(filtered);
  };

  const [basicModal5, setBasicModal5] = useState(false);

  const toggleOpen5 = () => setBasicModal5(!basicModal5);

  const [selectedCity, setSelectedCity] = useState("");

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption ? selectedOption.value : "");
  };

  const filterViewer = () => {
    const filtered = pendingjobs.filter((dummyData) => {
      const cityMatch =
        !selectedCity ||
        dummyData.city.toLowerCase().includes(selectedCity.toLowerCase());

      return cityMatch;
    });

    setdummyData(filtered);
    toggleOpen5();
  };

  const [page, setPage] = useState(1);
  const itemsPerPage = 28;

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  // Function to handle pagination
  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const [page1, setPage1] = useState(1);
  const itemsPerPage1 = 28;

  const startIndex1 = (page1 - 1) * itemsPerPage1;
  const endIndex1 = page1 * itemsPerPage1;

  // Function to handle pagination
  const nextPage1 = () => {
    setPage1(page1 + 1);
  };

  const prevPage1 = () => {
    setPage1(page1 - 1);
  };

  return (
    <div className="about">
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          Pending Jobs
        </h1>
        <MDBTabs className="mb-3">
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              Need To Be Assigned
            </MDBTabsLink>
          </MDBTabsItem>

          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
              Pending Approval
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}
            >
              Rejected
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <center>
          {basicActive === "tab1" ? (
            <>
              <h1 style={{ color: "#fe4e00" }}>Need To Be Assigned</h1>

              <MDBRow className="row-cols-1 row-cols-md-4 jobcards g-4">
                {jobs &&
                  jobs.slice(startIndex, endIndex).map((job) => {
                    return (
                      <MDBCol key={job.Id}>
                        <MDBCard
                          className="h-100"
                          style={{ backgroundColor: "#1a2229" }}
                        >
                          <MDBCardImage
                            style={{ height: "160px" }}
                            src={
                              job.packagename === "Basic Viewing"
                                ? "Assets/2.png"
                                : job.packagename === "Pro Viewing"
                                ? "Assets/4.png"
                                : "Assets/property.png"
                            }
                            position="top"
                            alt="..."
                          />

                          <MDBCardBody>
                            <MDBCardTitle
                              style={{ marginTop: "25px", color: "white" }}
                            >
                              {job.packagename}
                            </MDBCardTitle>

                            <MDBCardText>
                              <MDBBtn
                                style={{
                                  color: "white",
                                  backgroundColor: "#ff4e00",
                                }}
                                className="mx-2 my-2"
                                onClick={() => toggleOpen(job)}
                              >
                                Details
                              </MDBBtn>

                              {job.packagename !== "Property Search" ? (
                                <MDBBtn
                                  style={{
                                    color: "white",
                                    backgroundColor: "#ff4e00",
                                  }}
                                  className="mx-2 my-2"
                                  onClick={() => toggleOpen1(job)}
                                >
                                  AssignJob
                                </MDBBtn>
                              ) : (
                                ""
                              )}

                              <MDBBtn
                                style={{
                                  color: "white",
                                  backgroundColor: "#ff4e00",
                                }}
                                className="mx-2 my-2"
                                onClick={() => {
                                  toggleOpen2(job);
                                }}
                              >
                                AssignManually
                              </MDBBtn>
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    );
                  })}
              </MDBRow>
              <div className="d-flex justify-content-around mt-3">
                <MDBBtn onClick={prevPage} disabled={page === 1}>
                  Prev
                </MDBBtn>
                <MDBBtn
                  onClick={nextPage}
                  disabled={
                    (endIndex >= jobs && jobs.length) ||
                    (jobs && jobs.length <= itemsPerPage)
                  }
                >
                  Next
                </MDBBtn>
              </div>
            </>
          ) : basicActive === "tab2" ? (
            <center>
              <>
                <h1 style={{ color: "#fe4e00" }}>Pending Approval</h1>

                <div className="search mb-3  ms-auto mr-4">
                  <input
                    className="form-control bg-black mt-3"
                    label="Search"
                    id="form1"
                    type="text"
                    value={searchQuery1}
                    onChange={handleSearchChange1}
                    placeholder="Search"
                    style={{
                      color: "#fff",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />

                  <MDBBtn className="mt-3" onClick={toggleOpen5}>
                    filter
                  </MDBBtn>
                </div>

                <MDBModal
                  className="modalRegister"
                  open={basicModal5}
                  onClose={() => {
                    setBasicModal5(false);
                  }}
                  tabIndex="-1"
                >
                  <MDBModalDialog>
                    <MDBModalContent className="bg-dark">
                      <MDBModalHeader>
                        <MDBModalTitle>Modal title</MDBModalTitle>
                        <MDBBtn
                          className="btn-close"
                          color="none"
                          onClick={toggleOpen5}
                        ></MDBBtn>
                      </MDBModalHeader>
                      <MDBModalBody>
                        <div className="mb-3">
                          <label htmlFor="citySelect" className="form-label">
                            City
                          </label>

                          <Select
                            options={viewers.map((viewer) => ({
                              value: viewer.city,
                              label: viewer.city,
                            }))}
                            styles={customStyles}
                            placeholder="Choose your city"
                            onChange={handleCityChange}
                          />
                        </div>
                      </MDBModalBody>
                      <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={toggleOpen5}>
                          Close
                        </MDBBtn>
                        <MDBBtn onClick={filterViewer}>Save changes</MDBBtn>
                      </MDBModalFooter>
                    </MDBModalContent>
                  </MDBModalDialog>
                </MDBModal>

                <MDBRow
                  className="row-cols-1 row-cols-md-4 g-4"
                  style={{ margin: "30px" }}
                >
                  {pendingfilter &&
                    pendingfilter
                      .slice(startIndex1, endIndex1)
                      .map((approval) => {
                        return (
                          <MDBCol>
                            <MDBCard
                              className="h-100"
                              style={{ backgroundColor: "#1a2229" }}
                            >
                              <MDBCardImage
                                style={{ height: "160px" }}
                                src={
                                  approval.packagename === "Basic Viewing"
                                    ? "Assets/2.png"
                                    : approval.packagename === "Pro Viewing"
                                    ? "Assets/4.png"
                                    : "Assets/property.png"
                                }
                                position="top"
                                alt="..."
                              />

                              <MDBCardBody>
                                <MDBCardTitle
                                  style={{ marginTop: "25px", color: "white" }}
                                >
                                  {approval.packagename}
                                </MDBCardTitle>

                                <MDBCardText>
                                  <MDBBtn
                                    style={{
                                      color: "white",
                                      backgroundColor: "#ff4e00",
                                    }}
                                    className="mx-2 my-2"
                                    onClick={() => {
                                      toggleOpen(approval);
                                    }}
                                  >
                                    Details
                                  </MDBBtn>

                                  {approval.packagename !==
                                  "Property Search" ? (
                                    <MDBBtn
                                      style={{
                                        color: "white",
                                        backgroundColor: "#ff4e00",
                                      }}
                                      className="mx-2 my-2"
                                      onClick={() => toggleOpen1(approval)}
                                    >
                                      AssignJob
                                    </MDBBtn>
                                  ) : (
                                    ""
                                  )}
                                  <MDBBtn
                                    style={{
                                      color: "white",
                                      backgroundColor: "#ff4e00",
                                    }}
                                    className="mx-2 my-2"
                                    onClick={() => {
                                      toggleOpen2(approval);
                                    }}
                                  >
                                    AssignManually
                                  </MDBBtn>
                                </MDBCardText>

                                <h5
                                  style={{ color: "#ff4e00" }}
                                  className="card-title"
                                >
                                  Pending Confirmation By
                                </h5>
                                <div className="assigned-to-details">
                                  <MDBCardText className="assigned-to-name text-white">
                                    Name: {approval.firstname}{" "}
                                    {approval.lastname}
                                  </MDBCardText>
                                  <MDBCardText className="assigned-to-email  text-white">
                                    Ref No:{approval.Id+1000}
                                  </MDBCardText>
                                </div>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        );
                      })}
                </MDBRow>

                <div className="d-flex justify-content-around mt-3">
                  <MDBBtn onClick={prevPage1} disabled={page1 === 1}>
                    Prev
                  </MDBBtn>
                  <MDBBtn
                    onClick={nextPage1}
                    disabled={
                      (endIndex1 >= pendingfilter && pendingfilter.length) ||
                      (pendingfilter && pendingfilter.length <= itemsPerPage1)
                    }
                  >
                    Next
                  </MDBBtn>
                </div>
              </>
            </center>
          ) : (
            <>
              <h1 style={{ color: "#fe4e00" }}>Rejected</h1>

              <MDBRow
                className="row-cols-1 row-cols-md-4 g-4"
                style={{ margin: "30px" }}
              >
                {rejectedjobs &&
                  rejectedjobs.map((job) => {
                    return (
                      <MDBCol key={job.Id}>
                        <MDBCard
                          className="h-100"
                          style={{ backgroundColor: "#1a2229" }}
                        >
                          <MDBCardImage
                            style={{ height: "160px" }}
                            src={
                              job.packagename === "Basic Viewing"
                                ? "Assets/2.png"
                                : job.packagename === "Pro Viewing"
                                ? "Assets/4.png"
                                : "Assets/property.png"
                            }
                            position="top"
                            alt="..."
                          />

                          <MDBCardBody>
                            <MDBCardTitle
                              style={{ marginTop: "25px", color: "white" }}
                            >
                              {job.packagename}
                            </MDBCardTitle>

                            <MDBCardText>
                              <MDBBtn
                                style={{
                                  color: "white",
                                  backgroundColor: "#ff4e00",
                                }}
                                className="mx-2 my-2"
                                onClick={() => toggleOpen(job)}
                              >
                                Details
                              </MDBBtn>

                              {job.packagename !== "Property Search" ? (
                                <MDBBtn
                                  style={{
                                    color: "white",
                                    backgroundColor: "#ff4e00",
                                  }}
                                  className="mx-2 my-2"
                                  onClick={() => toggleOpen1(job)}
                                >
                                  AssignJob
                                </MDBBtn>
                              ) : (
                                ""
                              )}
                              <MDBBtn
                                style={{
                                  color: "white",
                                  backgroundColor: "#ff4e00",
                                }}
                                className="mx-2 my-2"
                                onClick={() => {
                                  toggleOpen2(job);
                                }}
                              >
                                AssignManually
                              </MDBBtn>
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    );
                  })}
              </MDBRow>
            </>
          )}
        </center>
      </div>

      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Job Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md={12}>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    {selectedjob && selectedjob.packagename}
                  </MDBCardTitle>
                  <p
                    style={{
                      color: "#ff4e00",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    £{selectedjob && selectedjob.paid}
                  </p>

                  {selectedjob && selectedjob.packagename === "Basic Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedjob.Q1URL ? (
                            <a href={selectedjob.Q1URL} target="_blank" rel="noopener noreferrer">
                              {selectedjob.Q1URL}
                            </a>
                          ) : selectedjob.street1 ? (
                            `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                          ) : (
                            "N/A"
                          )}
                        </strong>
                        . They{" "}
                        <strong>
                          {selectedjob.Q3
                            ? selectedjob.Q3 === "yes"
                              ? "have"
                              : "have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can reach them on{" "}
                        <strong>
                          {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedjob.Q4
                            ? selectedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedjob.Q5 === "email" ? (
                          <strong> on {selectedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedjob.promo ? selectedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedjob && selectedjob.packagename === "Pro Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedjob.Q1URL ? (
                            <a href={selectedjob.Q1URL} target="_blank" rel="noopener noreferrer">
                              {selectedjob.Q1URL}
                            </a>
                          ) : selectedjob.street1 ? (
                            `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                          ) : (
                            "N/A"
                          )}
                        </strong>{" "}
                        They{" "}
                        <strong>
                          {selectedjob.Q2
                            ? selectedjob.Q2 === "yes"
                              ? "have specific concerns about the property"
                              : "have not specific concerns about the property"
                            : "N/A"}
                        </strong>{" "}
                        They are concerned about
                        <strong>
                          {selectedjob.Q2 === "yes"
                            ? `  ${selectedjob.Q2specify}`
                            : ""}
                        </strong>{" "}
                        They
                        <strong>
                          {selectedjob.Q3
                            ? selectedjob.Q3 === "yes"
                              ? "  have"
                              : "  have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can reach them on{" "}
                        <strong>
                          {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedjob.Q4
                            ? selectedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedjob.Q5 === "email" ? (
                          <strong>on {selectedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedjob.promo ? selectedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedjob &&
                    selectedjob.packagename !== "Basic Viewing" &&
                    selectedjob.packagename !== "Pro Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          They plan on living at{" "}
                          <strong>
                            {selectedjob.Q1 ? selectedjob.Q1 : "N/A"}
                          </strong>
                          . They plan on{" "}
                          <strong>
                            {selectedjob.Q6 ? selectedjob.Q6 : "N/A"}
                          </strong>
                          .
                        </p>

                        {selectedjob.Q6 === "Renting" && (
                          <p>
                            If it's renting; Their allocated budget for this
                            property is{" "}
                            <strong>
                              {selectedjob.Q2 ? selectedjob.Q2 : "N/A"}
                            </strong>
                            . They are looking to rent for{" "}
                            <strong>
                              {selectedjob.Q3 ? selectedjob.Q3 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        {selectedjob.Q6 === "Buying" && (
                          <p>
                            They want a property within the price range of{" "}
                            <strong>
                              {" "}
                              £{selectedjob.Q7 ? selectedjob.Q7 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        <p>
                          They are looking for a property with these
                          preferences:{" "}
                          <strong>
                            {selectedjob.Q4 ? selectedjob.Q4 : "N/A"}
                          </strong>
                          . They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "Email" ? (
                            <strong>on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                        </p>

                        <p>
                          The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                onClick={toggleOpen}
                style={{ color: "white", backgroundColor: "#ff4e00" }}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>

           
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        className="assignjobmodal"
        open={basicModal1}
        onClose={() => setBasicModal1(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#fe4e00" }}>
                Select Viewer for Assignment
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen1}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Select
                options={
                  sortedViews &&
                  sortedViews.map((viewer) => ({
                    label: `${viewer.firstname} ${viewer.lastname}`,
                    value: viewer.viewersId,
                  }))
                }
                styles={customStyles}
                onChange={(selectedOption) =>
                  handleAssignViewer(selectedOption)
                }
                placeholder="Select"
              />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen1}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleassign}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        className="assignjobmodal"
        open={basicModal2}
        onClose={() => setBasicModal2(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#fe4e00" }}>
                Manual Assigment
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen2}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Select
                options={
                  viewers &&
                  viewers.map((viewer) => ({
                    label: `${viewer.firstname} ${viewer.lastname}`,
                    value: viewer.viewersId,
                  }))
                }
                onChange={(selectedOption) =>
                  handleAssignViewer(selectedOption)
                }
                placeholder="Search A  Viewer By Name"
                styles={customStyles}
              />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen2}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleassign}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <Footer />
    </div>
  );
}
