import React, { useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBBtn,
} from "mdb-react-ui-kit";

import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import axios from "axios";
export default function Lognavbar() {
  const [openBasic, setOpenBasic] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [allowedPages, setAllowedPages] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const pagesArrayString = Cookies.get("pagesArray");
    if (pagesArrayString && pagesArrayString.trim() !== "") {
      try {
        const parsedPagesArray = JSON.parse(pagesArrayString);
        setAllowedPages(parsedPagesArray);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        setAllowedPages(null);
      }
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logout = async () => {
    const pagesArrayString = Cookies.get("pagesArray");
    const ID = Cookies.get("V_Id");

    if (pagesArrayString) {
      console.log(ID);
      try {
        const response = axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/Admin/Logoutsubadmin`,
          { ID }
        );
      } catch (error) {}
    }

    Cookies.remove("V_email");
    Cookies.remove("V_token");
    Cookies.remove("V_Id");
    Cookies.remove("pagesArray");
    Cookies.remove("pagesArray");
    Cookies.remove("V_Picture");

    window.location.href = "/";
  };

  return (
    <>
      {allowedPages !== null ? (
        <MDBNavbar
          expand="lg"
          style={{
            position: "sticky",
            top: "0",
            backgroundColor: "black",
            zIndex: "9999",
          }}
        >
          <MDBContainer fluid>
          <MDBNavbarBrand href="#">
              <Link to="/">
                <div
                  style={{
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                <img src="Assets/logo.png" className="img-fluid logomain" alt="" />
                </div>
              </Link>
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setOpenBasic(!openBasic)}
            >
              <MDBIcon icon="bars" fas style={{ color: "white" }} />
            </MDBNavbarToggler>

            <MDBCollapse navbar open={openBasic} style={{ color: "white" }}>
              <MDBNavbarNav
                className={isMobile ? "justify-content-start align-items-start" : "justify-content-end"}
                style={{ marginTop: "20px" }}
              >
                {allowedPages && allowedPages.includes("Main Page") && (
                   <MDBDropdown group>
                   <MDBDropdownToggle
                     size="lg"
                     className="mt-1 viewerstoggle"
                     color="black"
                   >
                     Main Page
                   </MDBDropdownToggle>
                   <MDBDropdownMenu>
                     <MDBDropdownItem>
                       <Link to="/mainpageupdates" className="dropdown-item">
                         Main Page Updates
                       </Link>
                     </MDBDropdownItem>


                     <MDBDropdownItem>
                       <Link to="/CheckSlideShow" className="dropdown-item">
                       Main Page Uploads
                       </Link>
                     </MDBDropdownItem>
                   </MDBDropdownMenu>
                 </MDBDropdown>
                )}

                {allowedPages && allowedPages.includes("VIEWERS PORTAL") && (
                  <MDBDropdown group>
                    <MDBDropdownToggle
                      size="lg"
                      className="mt-1 viewerstoggle"
                      color="black"
                    >
                      Viewers Portal
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem>
                        <Link to="/viewerregister" className="dropdown-item">
                          Viewer Register
                        </Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <Link to="/viewers-account" className="dropdown-item">
                          Viewers Accounts
                        </Link>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <Link to="/pending-jobs" className="dropdown-item">
                          Pending Jobs
                        </Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <Link to="/current-jobs" className="dropdown-item">
                          Current Jobs
                        </Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <Link to="/job-history" className="dropdown-item">
                          Jobs History
                        </Link>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                )}

                {allowedPages && allowedPages.includes("SEEKERS PORTAL") && (
                  <MDBDropdown group>
                    <MDBDropdownToggle
                      size="lg"
                      className="mt-1 viewerstoggle"
                      color="black"
                    >
                      Seekers Portal
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem>
                        <Link to="/seekers-accounts" className="dropdown-item">
                          Seekers Accounts
                        </Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <Link to="/packages-bought" className="dropdown-item">
                          Packages Bought
                        </Link>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <Link to="/seekers-ratings" className="dropdown-item">
                          Ratings and reviews
                        </Link>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                )}

                {allowedPages && allowedPages.includes("Sub-Admin") && (
                  <MDBDropdown group>
                    <MDBDropdownToggle
                      size="lg"
                      className="mt-1 viewerstoggle"
                      color="black"
                    >
                      Sub-Admin
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem>
                        <Link to="/createsubadmin" className="dropdown-item">
                          Create-Sub-Admin
                        </Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <Link to="/SubAdminHistory" className="dropdown-item">
                          Log History
                        </Link>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                )}

                {allowedPages && allowedPages.includes("FINANCE PORTAL") && (
                 
<MDBDropdown group>
                  <MDBDropdownToggle
                    size="lg"
                    className="mt-1 viewerstoggle"
                    color="black"
                  >
                    Finance Portal
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>

                  <MDBDropdownItem>
                      <Link to="/financedashboard" className="dropdown-item">
                       Finance Dashboard
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link to="/createpromocode" className="dropdown-item">
                        Create Promo Code
                      </Link>
                    </MDBDropdownItem>

                    

                    <MDBDropdownItem>
                      <Link to="/modifyprice" className="dropdown-item">
                        Update Packages Price
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link to="/updatepercentages" className="dropdown-item">
                        Update Percentages
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/earningshistory" className="dropdown-item">
                        Earnings History
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/withdrawal" className="dropdown-item">
                        WithDrawal Request
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link
                        to="/AcceptedWithdrawalRequest"
                        className="dropdown-item"
                      >
                        Accepted Requests
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>

              
                )}

                <MDBDropdown
                  show={isOpen}
                  onClick={toggleDropdown}
                  style={{ color: "white" }}
                >
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link d-flex align-items-center"
                    href="#"
                    style={{ color: "white" }}
                  >
                    <img
                      src={
                        Cookies.get("V_Picture") &&
                        Cookies.get("V_Picture").startsWith("https://")
                          ? Cookies.get("V_Picture")
                          : `${
                              process.env.REACT_APP_BASE_URL
                            }/files/${Cookies.get("V_Picture")}`
                      }
                      className="rounded-circle"
                      height="30"
                      width="30"
                      alt="Avatar"
                      loading="lazy"
                    />
                  </MDBDropdownToggle>

                  <MDBDropdownMenu style={{ padding: "10px" }}>
                    <MDBDropdownItem>
                      <Link onClick={logout} to="/">
                        Logout
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      ) : (
        <MDBNavbar
          expand="lg"
          style={{
            position: "sticky",
            top: "0",
            backgroundColor: "black",
            zIndex: "9999",
          }}
        >
          <MDBContainer fluid>
            <MDBNavbarBrand href="#">
              <Link to="/maindashboard">
                <div
                  style={{
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                <img src="Assets/logo.png" className="img-fluid logomain" alt="" />
                </div>
              </Link>
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setOpenBasic(!openBasic)}
            >
              <MDBIcon icon="bars" fas style={{ color: "white" }} />
            </MDBNavbarToggler>

            <MDBCollapse navbar open={openBasic} style={{ color: "white" }}>
              <MDBNavbarNav
                className={isMobile ? "justify-content-start align-items-start" : "justify-content-end"}
                style={{ marginTop: "20px" }}
              >
                <MDBDropdown group>
                   <MDBDropdownToggle
                     size="lg"
                     className="mt-1 viewerstoggle"
                     color="black"
                   >
                     Main Page
                   </MDBDropdownToggle>
                   <MDBDropdownMenu>
                     <MDBDropdownItem>
                       <Link to="/mainpageupdates" className="dropdown-item">
                         Main Page Updates
                       </Link>
                     </MDBDropdownItem>


                     <MDBDropdownItem>
                       <Link to="/CheckSlideShow" className="dropdown-item">
                       Main Page Uploads
                       </Link>
                     </MDBDropdownItem>
                   </MDBDropdownMenu>
                 </MDBDropdown>

                <MDBDropdown group>
                  <MDBDropdownToggle
                    size="lg"
                    className="mt-1 viewerstoggle"
                    color="black"
                  >
                    Viewers Portal
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <Link to="/viewerregister" className="dropdown-item">
                        Viewer Register
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/viewers-account" className="dropdown-item">
                        Viewers Accounts
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link to="/pending-jobs" className="dropdown-item">
                        Pending Jobs
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/current-jobs" className="dropdown-item">
                        Current Jobs
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/job-history" className="dropdown-item">
                        Jobs History
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>

                <MDBDropdown group>
                  <MDBDropdownToggle
                    size="lg"
                    className="mt-1 viewerstoggle"
                    color="black"
                  >
                    Seekers Portal
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <Link to="/seekers-accounts" className="dropdown-item">
                        Seekers Accounts
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/packages-bought" className="dropdown-item">
                        Packages Bought
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link to="/seekers-ratings" className="dropdown-item">
                        Ratings and reviews
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>

                <MDBDropdown group>
                  <MDBDropdownToggle
                    size="lg"
                    className="mt-1 viewerstoggle"
                    color="black"
                  >
                    Finance Portal
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>

                  <MDBDropdownItem>
                      <Link to="/financedashboard" className="dropdown-item">
                       Finance Dashboard
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link to="/createpromocode" className="dropdown-item">
                        Create Promo Code
                      </Link>
                    </MDBDropdownItem>

                    

                    <MDBDropdownItem>
                      <Link to="/modifyprice" className="dropdown-item">
                        Update Packages Price
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link to="/updatepercentages" className="dropdown-item">
                        Update Percentages
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/earningshistory" className="dropdown-item">
                        Earnings History
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/withdrawal" className="dropdown-item">
                        WithDrawal Request
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link
                        to="/AcceptedWithdrawalRequest"
                        className="dropdown-item"
                      >
                        Accepted Requests
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>

                <MDBDropdown group>
                  <MDBDropdownToggle
                    size="lg"
                    className="mt-1 viewerstoggle"
                    color="black"
                  >
                    Sub-Admin
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <Link to="/createsubadmin" className="dropdown-item">
                        Create-Sub-Admin
                      </Link>
                    </MDBDropdownItem>

                    <MDBDropdownItem>
                      <Link to="/SubAdminHistory" className="dropdown-item">
                        Log History
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>

                <MDBDropdown
                  show={isOpen}
                  onClick={toggleDropdown}
                  style={{ color: "white" }}
                >
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link d-flex align-items-center"
                    href="#"
                    style={{ color: "white" }}
                  >
                    <img
                      src={
                        Cookies.get("V_Picture") &&
                        Cookies.get("V_Picture").startsWith("https://")
                          ? Cookies.get("V_Picture")
                          : `${
                              process.env.REACT_APP_BASE_URL
                            }/files/${Cookies.get("V_Picture")}`
                      }
                      className="rounded-circle"
                      height="30"
                      width="30"
                      alt="Avatar"
                      loading="lazy"
                    />
                  </MDBDropdownToggle>

                  <MDBDropdownMenu style={{ padding: "10px" }}>
                    <MDBDropdownItem>
                      <Link to="/change-password">
                        Change Password
                      </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <Link onClick={logout} to="/">
                        Logout
                      </Link>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      )}
    </>
  );
}
