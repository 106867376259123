import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBtn,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";
import JobHistorySingleViewer from "./JobHistorySingleViewer";
import Auth from "./auth";
import axios from "axios";
import { useParams } from "react-router-dom";
export default function AllJobs() {
  Auth();
  const [basicActive, setBasicActive] = useState("tab1");
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal1, setBasicModal1] = useState(false);
  const [selectedassignedjob, setselectedassignedjob] = useState();
  const toggleOpen = (job) => {
    setBasicModal(!basicModal);

    setselectedassignedjob(job);
  };
  const toggleOpen1 = () => setBasicModal1(!basicModal1);

  const [assignedjobs, setassignedjobs] = useState();
  const [currentjobs, setcurrentjobs] = useState();
  const [completedjobs, setcompletedjobs] = useState();
  const [alljobs, setalljobs] = useState();

  
  const { viewersId } = useParams();
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      document.body.style.backgroundColor = "#000000";

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/getassignedjobsbyviewersid`,
          { viewersId }
        );
        console.log("Success:", response.data);
        setassignedjobs(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchcurrent = async () => {
      document.body.style.backgroundColor = "#000000";

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/getcurrentjobsbyviewersid`,
          { viewersId }
        );
        console.log("curret:", response.data);
        setcurrentjobs(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchcompleted = async () => {
      document.body.style.backgroundColor = "#000000";

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/getcompletedjobsbyviewersid`,
          { viewersId }
        );
        console.log("completed:", response.data);
        setcompletedjobs(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchall = async () => {
      document.body.style.backgroundColor = "#000000";

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/getalljobsbyviewersid`,
          { viewersId }
        );
        console.log("setalljobs:", response.data);
        setalljobs(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    fetchcompleted();
    fetchcurrent();
    fetchall();
  }, [viewersId]);

  return (
    <div className="about">
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >

{alljobs && alljobs[0] && alljobs[0].firstname}  {alljobs && alljobs[0] && alljobs[0].lastname} Jobs

          
        </h1>

        <center>
          <MDBRow
            className="row-cols-1 justify-content-between row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>{alljobs&&alljobs.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Assigned Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {assignedjobs && assignedjobs.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Current Jobs
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>{
                    currentjobs&&currentjobs.length
                  }</h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <center>
          <MDBTabs className=" my-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                className={`${basicActive === "tab1" ? "" : "text-white"}`}
                active={basicActive === "tab1"}
              >
                Check All Jobs Assigned
              </MDBTabsLink>
            </MDBTabsItem>

            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                className={`${basicActive === "tab2" ? "" : "text-white"}`}
                active={basicActive === "tab2"}
              >
                Check All Curret Jobs
              </MDBTabsLink>
            </MDBTabsItem>

            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                className={`${basicActive === "tab3" ? "" : "text-white"}`}
                active={basicActive === "tab3"}
              >
                Check Jobs History
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
          <>
            {basicActive === "tab1" ? (
               <MDBRow
               className="row-cols-1 row-cols-md-4 g-4"
               style={{ margin: '30px' }}
             >
               {assignedjobs && assignedjobs.length > 0 ? (
                 assignedjobs.map((assignedjob) => (
                   <MDBCol key={assignedjob.id}>
                     <MDBCard
                       className=""
                       style={{ backgroundColor: '#1a2229' }}
                     >
                       <MDBCardImage
                            style={{ height: "160px" }}
                            src={
                              assignedjob.packagename === "Basic Viewing"
                                ? "/Assets/2.png"
                                : assignedjob.packagename === "Pro Viewing"
                                ? "/Assets/4.png"
                                : "/Assets/property.png"
                            }
                            position="top"
                            alt="..."
                          />
                       <MDBCardBody>
                         <MDBCardTitle
                           style={{ marginTop: '25px', color: 'white' }}
                         >
                           {assignedjob.packagename}
                         </MDBCardTitle>
                        
                         <MDBCardText>
                           <MDBBtn
                             style={{
                               color: 'white',
                               backgroundColor: '#ff4e00'
                             }}
                             className="mx-2 my-2"
                             onClick={() => {
                               toggleOpen(assignedjob);
                             }}
                           >
                             Details
                           </MDBBtn>
                         </MDBCardText>
                       </MDBCardBody>
                     </MDBCard>
                   </MDBCol>
                 ))
               ) : (
                 <MDBCol>
                   <p style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                     There Are No Jobs Assigned.
                   </p>
                 </MDBCol>
               )}
             </MDBRow>
         
            ) : basicActive === "tab2" ? (
              <MDBRow
               className="row-cols-1 row-cols-md-4 g-4"
               style={{ margin: '30px' }}
             >
               {currentjobs && currentjobs.length > 0 ? (
                 currentjobs.map((assignedjob) => (
                   <MDBCol key={assignedjob.id}>
                     <MDBCard
                       className=""
                       style={{ backgroundColor: '#1a2229' }}
                     >
                      <MDBCardImage
                            style={{ height: "160px" }}
                            src={
                              assignedjob.packagename === "Basic Viewing"
                                ? "/Assets/2.png"
                                : assignedjob.packagename === "Pro Viewing"
                                ? "/Assets/4.png"
                                : "/Assets/property.png"
                            }
                            position="top"
                            alt="..."
                          />
                       <MDBCardBody>
                         <MDBCardTitle
                           style={{ marginTop: '25px', color: 'white' }}
                         >
                           {assignedjob.packagename}
                         </MDBCardTitle>
                      
                         <MDBCardText>
                           <MDBBtn
                             style={{
                               color: 'white',
                               backgroundColor: '#ff4e00'
                             }}
                             className="mx-2 my-2"
                             onClick={() => {
                               toggleOpen(assignedjob);
                             }}
                           >
                             Details
                           </MDBBtn>
                         </MDBCardText>
                       </MDBCardBody>
                     </MDBCard>
                   </MDBCol>
                 ))
               ) : (
                 <MDBCol>
                   <p style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                     There Are No Current Jobs .
                   </p>
                 </MDBCol>
               )}
             </MDBRow>
            ) : (
              <JobHistorySingleViewer completedjobs={completedjobs} />
            )}
          </>
        </center>
      </div>

      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Job Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md={12}>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    {selectedassignedjob && selectedassignedjob.packagename}
                  </MDBCardTitle>
                  <p
                    style={{
                      color: "#ff4e00",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    $
                    {selectedassignedjob && selectedassignedjob.packagename
                      ? selectedassignedjob.packagename === "Basic Viewing"
                        ? "100"
                        : selectedassignedjob.packagename === "Pro Viewing"
                        ? "200"
                        : "200"
                      : "Package name not available"}
                  </p>


                  {selectedassignedjob &&
                    selectedassignedjob.packagename === "Basic Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          The property can be found here,{" "}
                          <strong>
                            {selectedassignedjob.Q1URL
                              ? selectedassignedjob.Q1URL
                              : selectedassignedjob.street1
                              ? `${selectedassignedjob.street1}, ${selectedassignedjob.city}, ${selectedassignedjob.country}`
                              : "N/A"}
                          </strong>
                          . They{" "}
                          <strong>
                            {selectedassignedjob.Q3
                              ? selectedassignedjob.Q3 === "yes"
                                ? "have"
                                : "have not"
                              : "N/A"}
                          </strong>{" "}
                          communicated with agent/property management. We can
                          reach them on{" "}
                          <strong>
                            {selectedassignedjob.q3part1 ? selectedassignedjob.q3part1 : "N/A"}
                          </strong>
                          , and what they said was{" "}
                          <strong>
                            {selectedassignedjob.q3part2 ? selectedassignedjob.q3part2 : "N/A"}
                          </strong>
                          . They are{" "}
                          <strong>
                            {selectedassignedjob.Q4
                              ? selectedassignedjob.Q4 === "yes"
                                ? "okay"
                                : "not okay"
                              : "N/A"}
                          </strong>{" "}
                          with 10 working days to get viewing done.{" "}
                          {selectedassignedjob.Q4 === "no" ? (
                            <span>
                              They want it done in{" "}
                              <strong>{selectedassignedjob.Q4Date}</strong>.
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>
                          They want to be communicated via{" "}
                          <strong>
                            {selectedassignedjob.Q5 ? selectedassignedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedassignedjob.Q5 === "email" ? (
                            <strong> on {selectedassignedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                          . The promo code used is{" "}
                          <strong>
                            {selectedassignedjob.promo ? selectedassignedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}

                  {selectedassignedjob && selectedassignedjob.packagename === "Pro Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedassignedjob.Q1URL
                            ? selectedassignedjob.Q1URL
                            : selectedassignedjob.street1
                            ? `${selectedassignedjob.street1}, ${selectedassignedjob.city}, ${selectedassignedjob.country}`
                            : "N/A"}
                        </strong>{" "}
                        They{" "}
                        <strong>
                          {selectedassignedjob.Q2
                            ? selectedassignedjob.Q2 === "yes"
                              ? "have specific concerns about the property"
                              : "have not specific concerns about the property"
                            : "N/A"}
                        </strong>{" "}
                        They are concerned about
                        <strong>
                          {selectedassignedjob.Q2 === "yes"
                            ? `  ${selectedassignedjob.Q2specify}`
                            : ""}
                        </strong>{" "}
                        They
                        <strong>
                          {selectedassignedjob.Q3
                            ? selectedassignedjob.Q3 === "yes"
                              ? "  have"
                              : "  have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can
                        reach them on{" "}
                        <strong>
                          {selectedassignedjob.q3part1 ? selectedassignedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedassignedjob.q3part2 ? selectedassignedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedassignedjob.Q4
                            ? selectedassignedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedassignedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedassignedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedassignedjob.Q5 ? selectedassignedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedassignedjob.Q5 === "email" ? (
                          <strong>on {selectedassignedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedassignedjob.promo ? selectedassignedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedassignedjob &&
                    selectedassignedjob.packagename !== "Basic Viewing" &&
                    selectedassignedjob.packagename !== "Pro Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          They plan on living at{" "}
                          <strong>
                            {selectedassignedjob.Q1 ? selectedassignedjob.Q1 : "N/A"}
                          </strong>
                          . They plan on{" "}
                          <strong>
                            {selectedassignedjob.Q6 ? selectedassignedjob.Q6 : "N/A"}
                          </strong>
                          .
                        </p>

                        {selectedassignedjob.Q6 === "Renting" && (
                          <p>
                            If it's renting; Their allocated budget for this
                            property is{" "}
                            <strong>
                              {selectedassignedjob.Q2 ? selectedassignedjob.Q2 : "N/A"}
                            </strong>
                            . They are looking to rent for{" "}
                            <strong>
                              {selectedassignedjob.Q3 ? selectedassignedjob.Q3 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        {selectedassignedjob.Q6 === "Buying" && (
                          <p>
                            They want a property within the price range of{" "}
                            <strong>
                              {" "}
                              £{selectedassignedjob.Q7 ? selectedassignedjob.Q7 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        <p>
                          They are looking for a property with these
                          preferences:{" "}
                          <strong>
                            {selectedassignedjob.Q4 ? selectedassignedjob.Q4 : "N/A"}
                          </strong>
                          . They want to be communicated via{" "}
                          <strong>
                            {selectedassignedjob.Q5 ? selectedassignedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedassignedjob.Q5 === "Email" ? (
                            <strong>on {selectedassignedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                        </p>

                        <p>
                          The promo code used is{" "}
                          <strong>
                            {selectedassignedjob.promo ? selectedassignedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}

                  

                 

                
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                onClick={toggleOpen}
                style={{ color: "white", backgroundColor: "green" }}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        className="assignjobmodal"
        open={basicModal1}
        onClose={() => setBasicModal1(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle>Select Viewer for Assignment</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen1}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>...</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen1}>
                Close
              </MDBBtn>
              <MDBBtn onClick={toggleOpen1}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Footer />
    </div>
  );
}
