import React,{useState,useEffect} from 'react';  
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBTextArea,
    MDBRow
} from 'mdb-react-ui-kit';

export default function Contact(){
    const [basicModal, setBasicModal] = useState(false);
    const toggleOpen = () => setBasicModal(!basicModal);

    return(
        <div>
          

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog centered>
                    <MDBModalContent style={{backgroundColor:"black"}}>
                        <MDBModalHeader>
                        <MDBModalTitle style={{color:"#ff4e00"}}>Send Us Mail</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow style={{margin:"20px"}}>
                                <div className="mb-4">
                                    <label htmlFor="form1" style={{ textAlign: 'left', display: 'block',color:"white" }}>Message</label>
                                    <MDBTextArea wrapperClass='mb-4' rows={4} required 
                                        name='about'
                                    />
                                </div>
                                </MDBRow>
                            </MDBModalBody>

                        <MDBModalFooter>
                        <MDBBtn style={{color:"white",backgroundColor:"#ff4e00",marginLeft:"10px"}}>Send</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}