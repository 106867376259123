import React, { useState } from "react";
import { useEffect } from "react";

import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
  MDBFile,
  MDBBtn,
  MDBIcon,
  MDBTextArea,
  MDBAccordion,
  MDBAccordionItem,
  MDBInput,
} from "mdb-react-ui-kit";
import axios from "axios";
import Select from "react-select";
import Footer from "./footer";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import Auth from "./auth";

import Lognavbar from "../Components/lognavbar";
const CheckSlideShow = () => {
  Auth();

  const [images, setImages] = useState([]);
  const [images1, setImages1] = useState([]);

  const [Faqs, setFaqs] = useState([]);

  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";

    fetchslideshow1();
    fetchslideshow2();
    fetchFaqs();
  }, []);

  const fetchslideshow1 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getSlideshow1`
      );
      setImages(response.data.data);
    } catch (error) {}
  };

  const fetchslideshow2 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getSlideshow2`
      );
      setImages1(response.data.data);
    } catch (error) {}
  };

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getfaqs`
      );

      setFaqs(response.data.data);
    } catch (error) {}
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };

  const removeImage = async (index) => {
    console.log(index);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/Admin//slideshow1/${index}`
      );

      fetchslideshow1();
    } catch (error) {}
  };

  const removeImage2 = async (index) => {
    console.log(index);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/Admin//slideshow2/${index}`
      );

      fetchslideshow2();
    } catch (error) {}
  };

  const removeFaq = async (index) => {
    console.log(index);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/Admin//deletefaqs/${index}`
      );

      fetchFaqs();
    } catch (error) {}
  };

  return (
    <>
      <Lognavbar />
      <div className="container">
        <h1 style={{ color: "#fe4e00" }} className="mb-4">
          Main Page Uploads
        </h1>

        <h4 style={{ color: "#fe4e00" }} className="text-center">
          SlideShow1
        </h4>

        <div className="container">
          <div className="row">
            {images &&
              images.map((path, index) => {
                const imageURL = path.filename
                  ? `${process.env.REACT_APP_BASE_URL}/files/${path.filename}`
                  : path.simplePath;

                return (
                  <div key={index} className="col-md-3 mb-3">
                    <div className="position-relative w-100">
                      <img
                        className="img-fluid imagesinside"
                        src={imageURL}
                        alt={`Image ${index}`}
                      />
                      <MDBIcon
                        onClick={() => removeImage(path.id)}
                        className="crossicon "
                        fas
                        icon="trash-alt"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <h4 style={{ color: "#fe4e00" }} className="text-center">
          SlideShow2
        </h4>

        <div className="container">
          <div className="row">
            {images1 &&
              images1.map((path, index) => {
                const imageURL = path.filename
                  ? `${process.env.REACT_APP_BASE_URL}/files/${path.filename}`
                  : path.simplePath;

                return (
                  <div key={index} className="col-md-3 mb-3">
                    <div className="position-relative w-100">
                      <img
                        className="img-fluid imagesinside"
                        src={imageURL}
                        alt={`Image ${index}`}
                      />
                      <MDBIcon
                        onClick={() => removeImage2(path.id)}
                        className="crossicon "
                        fas
                        icon="trash-alt"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {Faqs && Faqs.length > 0 && (
                <h4 className="faq-heading">
                    FAQS
                </h4>
            )}

      <MDBAccordion className="custom-accordion" initialActive={1}>
        {Faqs &&
          Faqs.map((faq, index) => {
            return (
              <MDBAccordionItem
                key={index}
                collapseId={index + 1}
                headerTitle={faq.question}
                className="custom-accordion-item"
              >
                {faq.answer}
                <br />

                <MDBBtn  onClick={()=>{
                    removeFaq(faq.id)
                }} style={{ backgroundColor: "#fe4e00" }} >
                <MDBIcon far icon="trash-alt" />                </MDBBtn>
              </MDBAccordionItem>
            );
          })}
      </MDBAccordion>
      <Footer />
    </>
  );
};

export default CheckSlideShow;
