import React, { useState, useEffect } from "react";
import { MDBInput, MDBCol, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import Select from "react-select";
import axios from "axios";
import Footer from "./footer";
import Lognavbar from "../Components/lognavbar";
import Auth from "./auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdatePrice = () => {
  Auth();
  const [Price, setPrice] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [allprices, setallprices] = useState("");


  const fetchdata=async()=>{

    const response=await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin/getPrices`);

    console.log(response.data);
    setallprices(response.data);

  }
  useEffect(() => {
    document.body.style.color = "white";
    document.body.style.backgroundColor = "black";



    fetchdata();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updateprice = {
      Price,
      package: selectedPackage ? selectedPackage.value : null,
    };

    console.log('Submitting updateprice Data:', updateprice);

    const existingPackage = allprices.find(p => p.PackageName === updateprice.package);


    console.log(existingPackage);
    if (existingPackage) {
      const createdAt = new Date(existingPackage.CreatedAt);
      const now = new Date();
      const sevenDaysAgo = new Date(now.setDate(now.getDate() - 7));

      if (createdAt > sevenDaysAgo) {
        const confirmUpdate = window.confirm(
          `The package ${updateprice.package} was updated less than 7 days ago. Are you sure you want to update it?`
        );
        if (!confirmUpdate) {
          toast.info('Update cancelled', {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/priceupdate`,
        updateprice
      );
      console.log('Success:', response.data);
      toast.success('Price Updated Successfully', {
        position: toast.POSITION.TOP_CENTER,
      });

      setPrice('');
      setSelectedPackage(null);
      fetchdata(); 
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error updating price', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Change color of selected option
    }),
  };

  return (
    <>
      <Lognavbar />
      <ToastContainer />

      <div className="container subadminform">
        <div className="row w-100 justify-content-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <h2
                style={{ color: "#ff4e00", marginTop: "2%" }}
                className="mb-4 text-center"
              >
                Update Packages Price
              </h2>

              <Select
                className="mb-3 basic-single-select"
                classNamePrefix="select"
                name="package"
                value={selectedPackage}
                styles={customStyles}
                onChange={setSelectedPackage}
                options={[
                  { label: "Basic Viewing", value: "Basic Viewing" },
                  { label: "Property Search", value: "Property Search" },
                  { label: "Pro Viewing", value: "Pro Viewing" }
                ]}
                placeholder="Select Package"
              />
              <MDBRow className="mb-4">
              
                <MDBCol>
                  <MDBInput
                    id="Price"
                    label="Price"
                    type="number"
                    value={Price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>

              <MDBBtn type="submit" className="mb-4" block>
                Update Price
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UpdatePrice;
