import React, { useEffect, useState } from "react";
import "../App.css";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBInput,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
} from "mdb-react-ui-kit";
import axios from "axios";
import Select from "react-select";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import Auth from "./auth";
const SeekerAccountDetails = () => {
  Auth();
  const [seekers, setseekers] = useState([]);
  const [filteredseekers, setFilteredseekers] = useState();

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/getall`,
        {}
      );
      setseekers(response.data.data);
      setFilteredseekers(response.data.data);
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const [selectedSeeker, setselectedSeeker] = useState(null);
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = (seeker) => {
    console.log(seeker);
    setselectedSeeker(seeker);
    setBasicModal(!basicModal);
  };

  const [basicModal1, setBasicModal1] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [packagesbought, setpackagesbought] = useState();

  const toggleOpen1 = (viewer) => {
    setselectedSeeker(viewer);
    setBasicModal1(!basicModal1);
  };

  const toggleOpen2 = async (seekers) => {
    try {
      console.log(seekers.seekersId);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/packages/checkJobDetailsBySeekersId/${seekers.seekersId}`,
        {}
      );

      console.log("This is tesponse",response.data);

      setpackagesbought(response.data);
      setBasicModal2(!basicModal2);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterseekers(event.target.value);
  };

  const filterseekers = (query) => {
    const filtered = seekers.filter((viewer) => {
      return (
        viewer.firstName.toLowerCase().includes(query.toLowerCase()) ||
        viewer.lastName.toLowerCase().includes(query.toLowerCase()) ||
        viewer.email.toLowerCase().includes(query.toLowerCase()) ||
        viewer.dateOfBirth.toLowerCase().includes(query.toLowerCase()) ||
        viewer.accommodationType.toLowerCase().includes(query.toLowerCase()) ||
        viewer.ratings.toString().toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredseekers(filtered);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Adding leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding leading zero if needed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  const [formData, setFormData] = useState({
    seekersId: selectedSeeker && selectedSeeker.seekersId,
    firstName: "",
    lastName: "",
    profilePicture: null,
    gender: "",
    accommodationType: "",
    dob: "",
    email: "",
    mobile: "",
    password: "",
  });
  useEffect(() => {
    if (selectedSeeker) {
      setFormData({
        seekersId: selectedSeeker.seekersId,
        firstName: selectedSeeker.firstName || "",
        lastName: selectedSeeker.lastName || "",
        profilePicture: selectedSeeker.profilePicture || null,
        gender: selectedSeeker.gender || "",
        accommodationType: selectedSeeker.accommodationType || "",
        dob: selectedSeeker.dateOfBirth
          ? formatDate(selectedSeeker.dateOfBirth)
          : "",
        email: selectedSeeker.email || "",
        mobile: selectedSeeker.mobile || "",
        password: selectedSeeker.password || "",
      });
    }
  }, [selectedSeeker]);

  const handleChange = (e) => {
    const { id, value, type, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === "file" ? files[0] : value,
    }));
  };

  const handleGenderChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: selectedOption.value,
    }));
  };

  const handleAccommodationChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      accommodationType: selectedOption.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/profile/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response from server:", response.data);
      setFormData({
        firstName: "",
        lastName: "",
        profilePicture: null,
        gender: "",
        accommodationType: "",
        dob: "",
        email: "",
        mobile: "",
        password: "",
      });

      fetchdata();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const assignedJobIds = [
    ...new Set(packagesbought&&packagesbought.flatMap((package3) => package3.assignedJobs)),
  ];

  const currentJobIds = [
    ...new Set(packagesbought&&packagesbought.flatMap((package3) => package3.currentJobs)),
  ];


  const [page, setPage] = useState(1);
  const itemsPerPage = 50;

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }
  

  return (
    <>
      <Lognavbar />
      <div>
        <h1 style={{ color: "#ff4e00" }}>Seekers Accounts</h1>

        <center>
          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total seekers
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {seekers && seekers.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <div className="">
          <div className="search  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by Name, Email, Creation-date"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />
          </div>

          <MDBTable
            style={{ width: "95%" }}
            className="mx-auto mt-3"
            responsive
          >
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th>Name</th>
                <th>Rating</th>
                <th>Account Creation</th>
                <th>View Packages</th>

                <th>Details</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="text-white">
              {filteredseekers &&
                 filteredseekers.slice(startIndex, endIndex).map((seekers, index) => (
                  <tr key={seekers.seekersId}>
                    <td>
                      <div className="ms-3">
                        <p className="fw-bold mb-1">{seekers.firstName}</p>
                        <p className="text-muted mb-0">{seekers.email}</p>
                      </div>
                    </td>

                    <td>
                      {seekers.ratings === 0
                        ? 0
                        : Array.from({ length: seekers.ratings }).map(
                            (_, index) => (
                              <MDBIcon
                                key={index}
                                style={{ color: "#ff4e00" }}
                                icon="star"
                              />
                            )
                          )}
                    </td>

                    <td>
                      {convertToUKTime(seekers.accountCreationDate)}
                    </td>
                    <td>
                      <MDBBtn
                        color="info"
                        size="sm"
                        onClick={() => toggleOpen2(seekers)}
                      >
                        View Packages
                      </MDBBtn>
                    </td>
                    <td>
                      <MDBBtn
                        color="info"
                        size="sm"
                        onClick={() => toggleOpen(seekers)}
                      >
                        View Details
                      </MDBBtn>
                    </td>

                    <td>
                      <MDBBtn
                        color="info"
                        size="sm"
                        onClick={() => toggleOpen1(seekers)}
                      >
                        Edit Seeker
                      </MDBBtn>
                    </td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>

          <div className="d-flex justify-content-around mt-3">
                <MDBBtn onClick={prevPage} disabled={page === 1}>
                  Prev
                </MDBBtn>
                <MDBBtn onClick={nextPage} disabled={(endIndex >= filteredseekers&&filteredseekers.length)||(filteredseekers&&filteredseekers.length<=itemsPerPage)}>
                  Next
                </MDBBtn>
              </div>
        </div>

        <MDBModal
          className="modalRegister"
          open={basicModal}
          onClose={() => setBasicModal(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Seekers Details</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {selectedSeeker && (
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        src={
                          selectedSeeker.profilePicture
                            ? `${process.env.REACT_APP_BASE_URL}/files/${selectedSeeker.profilePicture}`
                            : "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png"
                        }
                        className="img-fluid w-100"
                        alt="Image Here"
                      />
                    </div>

                    <div className="col-md-9 text-start">
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00", width: "35%" }}
                          className=" fw-bolder "
                        >
                          FirstName:{" "}
                        </span>
                        <span className=" fw-bold ">
                          {selectedSeeker.firstName}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00", width: "35%" }}
                          className=" fw-bolder "
                        >
                          LastName:{"     "}
                        </span>
                        <span className=" fw-bold ">
                          {" "}
                          {selectedSeeker.lastName}
                        </span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00", width: "35%" }}
                          className=" fw-bolder "
                        >
                          Email:{" "}
                        </span>
                        <span className=" fw-bold ">
                          {" "}
                          {selectedSeeker.email}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00", width: "35%" }}
                          className=" fw-bolder"
                        >
                          Mobile :
                        </span>
                        <span className="fw-bold">{selectedSeeker.mobile}</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00", width: "35%" }}
                          className=" fw-bolder "
                        >
                          Accomodation :
                        </span>
                        <span className="fw-bold">
                          {" "}
                          {selectedSeeker.accommodationType}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal
          className="modalRegister"
          open={basicModal1}
          onClose={() => setBasicModal1(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Edit Seeker</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen1}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <MDBInput
                    label="First Name"
                    id="firstName"
                    type="text"
                    className="mb-3"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <MDBInput
                    label="Last Name"
                    id="lastName"
                    type="text"
                    className="mb-3"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                  <MDBInput
                    label=""
                    id="profilePicture"
                    type="file"
                    className="mb-3"
                    onChange={handleChange}
                  />
                  <Select
                    className="mb-3"
                    id="gender"
                    name="gender"
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                      { value: "Other", label: "Other" },
                    ]}
                    isMulti={false}
                    placeholder={
                      formData.gender !== null
                        ? formData.gender
                        : "Select Gender"
                    }
                    onChange={handleGenderChange}
                    styles={customStyles}
                  />
                  <Select
                    className="mb-3"
                    id="accommodationType"
                    name="accommodationType"
                    styles={customStyles}
                    options={[
                      { value: "student", label: "Student Accommodation" },
                      {
                        value: "nonstudent",
                        label: "Non-Student Accommodation",
                      },
                    ]}
                    customStyles
                    isMulti={false}
                    placeholder={
                      formData.accommodationType !== null
                        ? formData.accommodationType
                        : "Select Accomodation"
                    }
                    onChange={handleAccommodationChange}
                  />
                  <MDBInput
                    label="Date of Birth"
                    id="dob"
                    type="date"
                    className="mb-3"
                    value={formData.dob}
                    onChange={handleChange}
                  />
                  <MDBInput
                    label="Email"
                    id="email"
                    type="email"
                    className="mb-3"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <MDBInput
                    label="Mobile"
                    id="mobile"
                    type="tel"
                    className="mb-3"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                  <MDBInput
                    label="Password"
                    id="password"
                    type="password"
                    className="mb-3"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <MDBBtn color="secondary" type="submit">
                    Edit
                  </MDBBtn>
                </form>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen1}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal
          className="modalRegister"
          open={basicModal2}
          onClose={() => setBasicModal2(false)}
          tabIndex="-1"
        >
          <MDBModalDialog scrollable>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Packages Bought</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen2}
                ></MDBBtn>
              </MDBModalHeader>

              <MDBModalBody>
                <div>
                  <MDBRow className="row-cols-auto justify-content-center">
                    {packagesbought &&
                      packagesbought.map((package1, index) => (
                        <MDBCol key={index}>
                          {package1 &&
                          package1.packageNames &&
                          package1.packageNames.length > 0 ? (
                            package1.packageNames.map(
                              (packageName, innerIndex) => (
                                <MDBCard
                                  key={innerIndex}
                                  style={{ backgroundColor: "#FF4E00" }}
                                  className="mb-3"
                                >
                                  <MDBCardBody>
                                    <MDBCardTitle>{packageName}</MDBCardTitle>
                                   
                                  </MDBCardBody>
                                </MDBCard>
                              )
                            )
                          ) : (
                            <p>Seeker has no packages</p>
                          )}
                        </MDBCol>
                      ))}
                  </MDBRow>

                  {packagesbought &&
                    packagesbought.map(
                      (packageItem, index) =>
                        packageItem.packageNames &&
                        packageItem.packageNames.length > 0 && (
                          <React.Fragment key={index}>
                            <label className="fw-bolder fs-5" htmlFor="">
                              Pending Jobs Reference Numbers
                            </label>
                            <Select
                              className="mb-2"
                              options={assignedJobIds.map((id) => ({
                                label: id+1000,
                                value: id,
                              }))}
                              styles={customStyles}
                              placeholder="Pending Jobs Reference Numbers"
                              isReadOnly={true}
                            />
                            <label className="fw-bolder fs-5" htmlFor="">
                              Current Jobs Reference Numbers
                            </label>
                            <Select
                              className="mb-2"
                              options={currentJobIds.map((id) => ({
                                label: id+1000,
                                value: id,
                              }))}
                              styles={customStyles}
                              placeholder="Current Jobs Reference Numbers"
                              isReadOnly={true}
                            />
                          </React.Fragment>
                        )
                    )}
                </div>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen2}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>

      <Footer />
    </>
  );
};

export default SeekerAccountDetails;
