import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon
} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";
import { useParams } from 'react-router-dom';

export default function JobHistorySingleViewer({completedjobs}) {
  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  const {viewersId} = useParams();


  useEffect(()=>{


 console.log("viewersId",viewersId)

  },[])

  const viewers = [
    {
      name: "Meet",
      email:"gmail.com",
      jobName: "ABC",
      clientName: "ABC",
      status: "Working",
      rating: 5,
      refno:"1234567890"
    },

    {
      name: "Meet",
      email:"gmail.com",

      jobName: "XYZ",
      clientName: "XYZ",
      status: "Working",
      rating: 4,
      refno:"1234567890"

    },
    {
      name: "Meet",
      email:"gmail.com",

      jobName: "123",
      clientName: "123",
      status: "Done",
      rating: 3,
      refno:"1234567890"

    },
  ];

  const [filteredviewer, setviewer] = useState(completedjobs);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterviewer(event.target.value);
  };

  const filterviewer = (query) => {
    const filtered = completedjobs&&completedjobs.filter((viewer) => {
      return (
        viewer.firstname.toLowerCase().includes(query.toLowerCase()) ||
        viewer.lastname.toLowerCase().includes(query.toLowerCase()) ||
        viewer.job_id.toString().toLowerCase().includes(query.toLowerCase()) ||
        viewer.job_name.toLowerCase().includes(query.toLowerCase()) ||

        viewer.jobratings.toString().toLowerCase().includes(query.toLowerCase())
      );
    });
    setviewer(filtered);
  };

  return (
    <div className="about">
      <div className="head">
      

        <div >
          <div className="search mb-3  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />
          </div>
          <MDBTable className="mx-auto" style={{ width: "96%" }}>
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th scope="col">Name</th>

                <th scope="col">Job Ref Number</th>
                <th scope="col">Job Name</th>

                <th scope="col">Rating</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody style={{ color: "white" }}>
              {filteredviewer.map((viewer) => {
                return (
                  <tr>
                    <td>{viewer.firstname} {viewer.lastname}</td>
                    <td>{viewer.Id}</td>

                    <td>{viewer.packagename}</td>

                    <td>
                      {viewer.jobratings > 0 &&
                        Array.from({ length: viewer.jobratings }).map(
                          (_, index) => (
                            <MDBIcon
                              key={index}
                              style={{ color: "#ff4e00" }}
                              icon="star"
                            />
                          )
                        )}
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>

    </div>
  );
}
