import './App.css';
import { useState } from 'react';
import {Switch,Route} from 'react-router-dom';
import Home from './Pages/Home';
import Profile from './Pages/profile';
import Chat from './Pages/chat';
import History from './Pages/jobhistory';
import JobAssigned from './Pages/jobassigned';
import CurrentJobs from './Pages/currentjobs';
import Earnings from './Pages/earning';
import Details from './Pages/details';
import Details1 from './Pages/details1';
import Login from './Pages/login';
import Contact from './Pages/contact';
import CreateSubAdmin from './Pages/CreateSubAdmin';
import SubadminHistory from './Pages/SubadminHistory';

import MainPageUpdates from './Pages/MainPageUpdates';
import ViewerRegister from './Pages/viewerRegister';
import ViewerAccount from './Pages/ViewerAccount';
import PendingJobs from './Pages/PendingJobs';
import SeekersRatingsReviews from './Pages/SeekersRatingsReviews';
import SeekersPackagesBought from './Pages/SeekersPackagesBought';
import SeekerAccountDetails from './Pages/SeekerAccountDetails';
import AllJobs from './Pages/AllJobs';
import CreatePromoCode from './Pages/CreatePromoCode';
import UpdatePrice from './Pages/UpdatePrice';
import ChangePercentages from './Pages/ChangePercentages';
import EarningsHistory from './Pages/EarningsHistory';
import WithdrawalRequest from './Pages/WithdrawalRequest';
import Earningdetails from './Pages/Earningdetails';
import AcceptedWithdrawalRequest from './Pages/AcceptedWithdrawalRequest';
import CheckSlideShow from './Pages/CheckSlideShow';
import MainDashboard from './Pages/MainDashboard';
import FinanceDashboard from './Pages/FinanceDashboard';
import Changepassword from './Pages/changepassword';

function App() {
  return (
    <div className="App">
        <Switch>
          <Route exact path="/" component={Login}/>
          {/* <Route exact path="/home" component={Home}/> */}
          <Route exact path="/profile" component={Profile}/>
          <Route exact path="/chat" component={Chat}/>
          <Route exact path="/job-history" component={History}/>
          <Route exact path="/current-jobs" component={CurrentJobs}/>
          <Route exact path="/earnings" component={Earnings}/>
          <Route exact path="/job-details" component={Details}/>
          <Route exact path="/job-details1" component={Details1}/>

          <Route exact path="/createsubadmin" component={CreateSubAdmin}/>
          <Route exact path="/subadminhistory" component={SubadminHistory}/>
          <Route exact path="/mainpageupdates" component={MainPageUpdates}/>
          <Route exact path="/viewerregister" component={ViewerRegister}/>

          <Route exact path="/viewers-account" component={ViewerAccount}/>
          <Route exact path="/pending-jobs" component={PendingJobs}/>

          <Route exact path="/seekers-ratings" component={SeekersRatingsReviews}/>
          <Route exact path="/packages-bought" component={SeekersPackagesBought}/>
          <Route exact path="/seekers-accounts" component={SeekerAccountDetails}/>
          <Route exact path="/pending-jobs" component={PendingJobs}/>
          <Route exact path="/all-jobs/:viewersId" component={AllJobs}/>
          <Route exact path="/change-password" component={Changepassword}/>


          {/* //FinanceSection */}

          <Route exact path="/createpromocode" component={CreatePromoCode}/>
          <Route exact path="/modifyprice" component={UpdatePrice}/>
          <Route exact path="/updatepercentages" component={ChangePercentages}/>
          <Route exact path="/earningshistory" component={EarningsHistory}/>
          <Route exact path="/withdrawal" component={WithdrawalRequest}/>
          <Route exact path="/earningdetails/:viewerId" component={Earningdetails}/>
          <Route exact path="/AcceptedWithdrawalRequest" component={AcceptedWithdrawalRequest}/>

          <Route exact path="/CheckSlideShow" component={CheckSlideShow}/>

          <Route exact path="/maindashboard" component={MainDashboard}/>
          <Route exact path="/financedashboard" component={FinanceDashboard}/>

          
        </Switch>
        <Contact/>
    </div>
  );
}

export default App;
