import React, { useState } from "react";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import Auth from "./auth";
import axios from "axios";
const SubadminHistory = () => {

  Auth();
  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);
const [subadmins,setsubadmins]=useState();
const [filteredsubadmin, setsubadmin] = useState();

  const fetchjobhistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getsubadmin`
      );
      console.log("subadmins", response.data);
      setsubadmins(response.data.data);
      setsubadmin(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchjobhistory();
  }, []);


  const dummyData = [
    {
      name: "John Doe",
      email: "john.doe@gmail.com",
      creationDate: "5/5/2024, 8:14 PM",
      lastLogin: "5/5/2024, 8:14 PM",
    },
    {
      name: "Jane Smith",
      email: "jane.smith@example.com",
      creationDate: "5/6/2024, 9:30 AM",
      lastLogin: "5/6/2024, 9:30 AM",
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filtersubadmin(event.target.value);
  };

  const filtersubadmin = (query) => {
    const filtered = dummyData.filter((subadmin) => {
      return (
        subadmin.name.toLowerCase().includes(query.toLowerCase()) ||
        subadmin.email.toLowerCase().includes(query.toLowerCase()) ||
        subadmin.lastLogin.toLowerCase().includes(query.toLowerCase()) ||
        subadmin.creationDate.toLowerCase().includes(query.toLowerCase())
      );
    });
    setsubadmin(filtered);
  };
  return (
    <>
      <Lognavbar />
      <h1 className="mainheading" style={{ color: "#ff4e00", marginTop: "2%" }}>
        Login History
      </h1>{" "}
      <div className="logoffHistorytable d-flex flex-column justify-content-center align-items-center">
        <div className="search  ms-auto mr-4">
          <input
            className="form-control bg-black mt-3"
            label="Search"
            id="form1"
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search"
            style={{
              color: "#fff",
              border: "1px solid #ced4da",
              borderRadius: "5px",
            }}
          />
        </div>
        <MDBTable style={{ width: "95%" }} align="middle" responsive className=" mx-auto mt-2">
          <MDBTableHead
            style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
          >
            <tr>
            <th scope="col"></th>

              <th scope="col">Name</th>
              <th scope="col">Login Time</th>
              <th scope="col">LogOff Time</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody style={{ color: "white" }}>
            {filteredsubadmin&&filteredsubadmin.map((item, index) => (
              <tr key={index}>
                <td>
                {(item.logoffTime === null)&&(item.loginTime!==null) ? (
                      <div style={{height:"10px",width:"10px",backgroundColor: "#ff4e00"}}
                        className="active-icon  rounded-circle" 
                        title="Active"
                      >
                        
                      </div>
                    ) : (
                      <div style={{height:"10px",width:"10px",backgroundColor:"gray"}}
                        className="inactive-icon mt-3 rounded-circle"
                        title="Inactive"
                      ></div>
                    )}
                </td>
                <td>
                  <div className="ms-3">
                   

                    <p className="fw-bold mb-1">{item.firstName} {item.lastName}</p>
                    <p className="text-muted mb-0">{item.email}</p>
                  </div>
                </td>

                <td>
                <p className="fw-bold mb-1">{item.loginTime ? new Date(item.loginTime).toLocaleString() : 'N/A'}</p>
                </td>
                <td>
                  <p className="fw-bold mb-1">{item.logoffTime ? new Date(item.logoffTime).toLocaleString() : 'N/A'}</p>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
      <Footer />
    </>
  );
};

export default SubadminHistory;
