import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

const Charts1 = () => {
  const [monthlyData, setMonthlyData] = useState([]);

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getMonthlyDataFinance`
      );

      console.log("response.data.monthlyData",response.data.monthlyData)
      setMonthlyData(response.data.monthlyData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false
      },
      background: "#212121", // Dark background color
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      width: [3, 3], // Increase line width for both series
    },
    title: {
      text: "Earnings And Packages Bought",
      align: "left",
      style: {
        color: "#ffffff", // White title text
        fontSize: "20px", // Larger font size
      }
    },
    grid: {
      borderColor: "#555555",
    },
    xaxis: {
      categories: monthlyData.map(data => data.monthName),
      labels: {
        style: {
          colors: "#ffffff" 
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: "#ffffff"
        }
      }
    },
    colors: ["#ffd166", "#06d6a0"] // Set custom colors for series
  };

  const series = [
    {
      name: "Total Earnings",
      data: monthlyData.map(data => data.totalEarnings)    
    },
    {
      name: "Total Packages Bought",
      data: monthlyData.map(data => data.totalPackages)
    }
,
    {
        name: "With-Draw Requests",
        data: monthlyData.map(data => data.totalWithdraws)
      }
  ];

  return (
    <div className="my-5">
      <div className="m-auto" style={{ backgroundColor: "#212121", width: "95%", color: "black", padding: "20px", borderRadius: "10px" }}>
        <div id="chart">
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </div>
      </div>
    </div>
  );
};

export default Charts1;
