import React, { useEffect, useState } from "react";
import "../App.css";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBInput,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Select from "react-select";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import Auth from "./auth";
import axios from "axios";
const SeekersRatingsReviews = () => {
  Auth();

  const [filteredseekers, setFilteredseekers] = useState();

  const [seekers, setseekers] = useState([]);

  useEffect(() => {
    const fetchall = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/seekersratings`
        );
        console.log("setalljobs:", response.data);
        setseekers(response.data.data);
        setFilteredseekers(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchall();
  }, []);

  const [selectedseeker, setSelectedseeker] = useState(null);
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = (seeker) => {
    setSelectedseeker(seeker);
    setBasicModal(!basicModal);
  };

  const [basicModal1, setBasicModal1] = useState(false);

  const toggleOpen1 = (seeker) => {
    setSelectedseeker(seeker);
    setBasicModal1(!basicModal1);
  };
  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterseekers(event.target.value);
  };

  const filterseekers = (query) => {
    console.log(seekers);
    const filtered =
      seekers &&
      seekers.filter((seeker) => {
        return (
          seeker.firstName.toLowerCase().includes(query.toLowerCase()) ||
          seeker.lastName.toLowerCase().includes(query.toLowerCase()) ||
          seeker.email.toLowerCase().includes(query.toLowerCase()) ||
          seeker.jobratings.toString().toLowerCase().includes(query.toLowerCase()) ||

          seeker.Id.toString().includes(query)
        );
      });
    setFilteredseekers(filtered);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  const [basicModal5, setBasicModal5] = useState(false);

  const toggleOpen5 = () => setBasicModal5(!basicModal5);

  const [selectedjob, setselectedjob] = useState("");

  const handleselect = async (job) => {
    setselectedjob(job);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/profile/get`,
        {
          params: {
            Id: job.viewersId,
          },
        }
      );

      setselectedjob({ ...job, viewerData: response.data.data });
    } catch (error) {
      console.error("Error:", error);
    }

    setBasicModal5(true);
  };

  const [page, setPage] = useState(1);
  const itemsPerPage = 28;

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };
  return (
    <>
      <Lognavbar />
      <div>
        <h1 style={{ color: "#ff4e00" }}>Seekers Ratings</h1>

        <div className="">
          <div className="search  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />
          </div>

          <MDBTable
            style={{ width: "95%" }}
            className="mx-auto mt-3"
            responsive
          >
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th>Name</th>
                <th>Job Reference</th>
                <th>Ratings</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="text-white">
              {filteredseekers &&
                filteredseekers.slice(startIndex,endIndex).map((seeker, index) => (
                  <tr key={seeker.id}>
                    <td>
                      <div className="ms-3">
                        <p className="fw-bold mb-1">
                          {seeker.firstName} {seeker.lastName}
                        </p>
                        <p className="text-muted mb-0">{seeker.email}</p>
                      </div>
                    </td>

                    <td>
                      <MDBBtn
                        onClick={() => {
                          handleselect(seeker);
                        }}
                      >
                        {seeker.Id+1000}
                      </MDBBtn>
                    </td>

                    <td>
                      {seeker.jobratings > 0
                        ? Array.from({ length: seeker.jobratings }).map(
                            (_, index) => (
                              <MDBIcon
                                key={index}
                                style={{ color: "#ff4e00" }}
                                icon="star"
                              />
                            )
                          )
                        : "N/A"}
                    </td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>

          <div className="d-flex justify-content-around mt-3">
            <MDBBtn onClick={prevPage} disabled={page === 1}>
              Prev
            </MDBBtn>
            <MDBBtn
              onClick={nextPage}
              disabled={
                (endIndex >= filteredseekers && filteredseekers.length) ||
                (filteredseekers && filteredseekers.length <= itemsPerPage)
              }
            >
              Next
            </MDBBtn>
          </div>
        </div>

        <MDBModal
          open={basicModal5}
          onClose={() => setBasicModal5(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent style={{ backgroundColor: "black" }}>
              <MDBModalHeader>
                <MDBModalTitle style={{ color: "#ff4e00" }}>
                  Job Details
                </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleOpen5}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol md={12}>
                    <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                      {selectedjob && selectedjob.packagename}
                    </MDBCardTitle>
                    <p
                      style={{
                        color: "#ff4e00",
                        fontWeight: "bold",
                        fontSize: "19px",
                      }}
                    >
                      $
                      {selectedjob && selectedjob.packagename
                        ? selectedjob.packagename === "Basic Viewing"
                          ? "100"
                          : selectedjob.packagename === "Pro Viewing"
                          ? "200"
                          : "200"
                        : "Package name not available"}
                    </p>


                    {selectedjob &&
                    selectedjob.packagename === "Basic Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          The property can be found here,{" "}
                          <strong>
                            {selectedjob.Q1URL
                              ? selectedjob.Q1URL
                              : selectedjob.street1
                              ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                              : "N/A"}
                          </strong>
                          . They{" "}
                          <strong>
                            {selectedjob.Q3
                              ? selectedjob.Q3 === "yes"
                                ? "have"
                                : "have not"
                              : "N/A"}
                          </strong>{" "}
                          communicated with agent/property management. We can
                          reach them on{" "}
                          <strong>
                            {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                          </strong>
                          , and what they said was{" "}
                          <strong>
                            {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                          </strong>
                          . They are{" "}
                          <strong>
                            {selectedjob.Q4
                              ? selectedjob.Q4 === "yes"
                                ? "okay"
                                : "not okay"
                              : "N/A"}
                          </strong>{" "}
                          with 10 working days to get viewing done.{" "}
                          {selectedjob.Q4 === "no" ? (
                            <span>
                              They want it done in{" "}
                              <strong>{selectedjob.Q4Date}</strong>.
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>
                          They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "email" ? (
                            <strong> on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                          . The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}

                  {selectedjob && selectedjob.packagename === "Pro Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedjob.Q1URL
                            ? selectedjob.Q1URL
                            : selectedjob.street1
                            ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                            : "N/A"}
                        </strong>{" "}
                        They{" "}
                        <strong>
                          {selectedjob.Q2
                            ? selectedjob.Q2 === "yes"
                              ? "have specific concerns about the property"
                              : "have not specific concerns about the property"
                            : "N/A"}
                        </strong>{" "}
                        They are concerned about
                        <strong>
                          {selectedjob.Q2 === "yes"
                            ? `  ${selectedjob.Q2specify}`
                            : ""}
                        </strong>{" "}
                        They
                        <strong>
                          {selectedjob.Q3
                            ? selectedjob.Q3 === "yes"
                              ? "  have"
                              : "  have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can
                        reach them on{" "}
                        <strong>
                          {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedjob.Q4
                            ? selectedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedjob.Q5 === "email" ? (
                          <strong>on {selectedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedjob.promo ? selectedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedjob &&
                    selectedjob.packagename !== "Basic Viewing" &&
                    selectedjob.packagename !== "Pro Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          They plan on living at{" "}
                          <strong>
                            {selectedjob.Q1 ? selectedjob.Q1 : "N/A"}
                          </strong>
                          . They plan on{" "}
                          <strong>
                            {selectedjob.Q6 ? selectedjob.Q6 : "N/A"}
                          </strong>
                          .
                        </p>

                        {selectedjob.Q6 === "Renting" && (
                          <p>
                            If it's renting; Their allocated budget for this
                            property is{" "}
                            <strong>
                              {selectedjob.Q2 ? selectedjob.Q2 : "N/A"}
                            </strong>
                            . They are looking to rent for{" "}
                            <strong>
                              {selectedjob.Q3 ? selectedjob.Q3 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        {selectedjob.Q6 === "Buying" && (
                          <p>
                            They want a property within the price range of{" "}
                            <strong>
                              {" "}
                              £{selectedjob.Q7 ? selectedjob.Q7 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        <p>
                          They are looking for a property with these
                          preferences:{" "}
                          <strong>
                            {selectedjob.Q4 ? selectedjob.Q4 : "N/A"}
                          </strong>
                          . They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "Email" ? (
                            <strong>on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                        </p>

                        <p>
                          The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}
                   

                    <h2
                      style={{
                        borderColor: "#FD4D00",
                        borderStyle: "solid",
                        borderWidth: "2px 0 2px 0",
                        padding: "16px",
                      }}
                    >
                      Viewer Details
                    </h2>

                    <label className="detail-label">Name: <span style={{color:"white",marginLeft:"10px"}}>{selectedjob &&
                        selectedjob.viewerData &&
                        selectedjob.viewerData.length > 0 && (
                          <>
                            {selectedjob.viewerData[0].firstname}{" "}
                            {selectedjob.viewerData[0].lastname}
                          </>
                        )}</span></label>

                    <label className="detail-label">
                      Email: 
                      <span style={{color: "white",marginLeft:"10px"}}>
                        {selectedjob &&
                          selectedjob.viewerData &&
                          selectedjob.viewerData.length > 0 && (
                            <>{selectedjob.viewerData[0].email}</>
                          )}
                      </span>
                    </label>

                    <label className="detail-label">
                      Mobile: 
                      <span style={{color: "white",marginLeft:"10px"}}>
                        {selectedjob &&
                          selectedjob.viewerData &&
                          selectedjob.viewerData.length > 0 && (
                            <>{selectedjob.viewerData[0].mobile}</>
                          )}
                      </span>
                    </label>

                    <label className="detail-label">
                      Gender: 
                      <span style={{color: "white",marginLeft:"10px"}}>
                        {selectedjob &&
                          selectedjob.viewerData &&
                          selectedjob.viewerData.length > 0 && (
                            <>{selectedjob.viewerData[0].gender}</>
                          )}
                      </span>
                    </label>

                    <label className="detail-label">
                      City: 
                      <span style={{color: "white",marginLeft:"10px"}}>
                        {selectedjob &&
                          selectedjob.viewerData &&
                          selectedjob.viewerData.length > 0 && (
                            <>{selectedjob.viewerData[0].city}</>
                          )}
                      </span>
                    </label>

                    <label className="detail-label">
                      Country: 
                      <span style={{color: "white",marginLeft:"10px"}}>
                        {selectedjob &&
                          selectedjob.viewerData &&
                          selectedjob.viewerData.length > 0 && (
                            <>{selectedjob.viewerData[0].country}</>
                          )}
                      </span>
                    </label>

                    <label className="detail-label">
                      PostCode: 
                      <span style={{color: "white",marginLeft:"10px"}}>
                        {selectedjob &&
                          selectedjob.viewerData &&
                          selectedjob.viewerData.length > 0 && (
                            <>{selectedjob.viewerData[0].postcode}</>
                          )}
                      </span>
                    </label>

                  </MDBCol>
                </MDBRow>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn
                  onClick={toggleOpen5}
                  style={{ color: "white", backgroundColor: "green" }}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal
          className="modalRegister"
          open={basicModal}
          onClose={() => setBasicModal(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Seekers Details</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {selectedseeker && (
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YXZhdGFyfGVufDB8fDB8fHww"
                        className="img-fluid w-75"
                        alt=""
                      />
                    </div>

                    <div className="col-md-8 text-start">
                      <p className="fw-bold mb-1">John Doe</p>
                      <p className="text-muted mb-0">John.doe@gmail.com</p>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Gender :{" "}
                        </span>
                        <span className=" fw-bold">Male</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          DOB :{" "}
                        </span>
                        <span className=" fw-bold">5/5/2024, 8:50 AM</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Mobile :
                        </span>
                        <span className="fw-bold">+92 0000000000</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Street 1 :{" "}
                        </span>
                        <span className="fw-bold">Street No 1</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 2 :{" "}
                        </span>
                        <span className=" fw-bold">Street No 2</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 3 :{" "}
                        </span>
                        <span className=" fw-bold">Street No 3</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          City :
                        </span>
                        <span className=" fw-bold">City</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25  fw-bolder"
                        >
                          Postcode:
                        </span>
                        <span className=" fw-bold">20233</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Country :
                        </span>
                        <span className="fw-bold">Country</span>
                      </div>

                      <div>
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder"
                        >
                          About
                        </span>
                        <p className=" fw-bold text-justify">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Harum ducimus doloremque modi maiores. Eum
                          laborum, obcaecati nostrum quisquam saepe architecto
                          totam voluptate mollitia reiciendis veritatis, porro
                          velit. Molestias, eius eum.
                        </p>
                      </div>

                      <MDBBtn className=" w-100 btn">Download CV</MDBBtn>
                    </div>
                  </div>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal
          className="modalRegister"
          open={basicModal1}
          onClose={() => setBasicModal1(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Edit Seeker</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen1}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <form>
                  <MDBInput
                    label="First Name"
                    id="firstName"
                    type="text"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Last Name"
                    id="lastName"
                    type="text"
                    className="mb-3"
                  />
                  <MDBInput
                    label=""
                    id="profilePicture"
                    type="file"
                    className="mb-3"
                  />

                  <Select
                    className="mb-3"
                    id="multi-select"
                    name="gender"
                    required
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                      { value: "Other", label: "Other" },
                    ]}
                    isMulti={false}
                    styles={customStyles}
                    placeholder="Select Answer"
                  />

                  <Select
                    className="mb-3"
                    id="multi-select"
                    name="gender"
                    required
                    options={[
                      { value: "student", label: "Student Accomodation" },
                      {
                        value: "nonstudent",
                        label: "Non-Student Accomodation",
                      },
                    ]}
                    isMulti={false}
                    styles={customStyles}
                    placeholder="Select Answer"
                  />

                  <MDBInput
                    label="Date of Birth"
                    id="dob"
                    type="date"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Email"
                    id="email"
                    type="email"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Mobile"
                    id="mobile"
                    type="tel"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Password"
                    id="password"
                    type="password"
                    className="mb-3"
                  />
                </form>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen1}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>

      <Footer />
    </>
  );
};

export default SeekersRatingsReviews;
