import React, {useEffect,useState} from 'react';
import Lognavbar from '../Components/lognavbar';
import {MDBBtn} from 'mdb-react-ui-kit';
import WebSocket from 'websocket';
import Cookies from 'js-cookie';
import axios from 'axios';
import {MDBRow,MDBCol} from 'mdb-react-ui-kit';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [ws, setWs] = useState(null);
  const sentMessages = new Map();
  const audioRef = React.useRef();

  function generateMessageId() {
    return Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    GetReviews();
    document.body.style.backgroundColor = "#000000";
    const newWs = new WebSocket.w3cwebsocket('ws://localhost:5000');
    setWs(newWs);

    newWs.onopen = () => {
      console.log('WebSocket connected');
    };

    newWs.onmessage = (messageEvent) => {
      const receivedMessage = JSON.parse(messageEvent.data);
      setMessages(prevMessages => [...prevMessages, receivedMessage]);
      const messageId = receivedMessage.id;
    
      if (sentMessages.has(messageId)) {
        const message = sentMessages.get(messageId);
        message.status = 'read';
        sentMessages.set(messageId, message);
      }
    
    };

    newWs.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      newWs.close();
    };
  }, []);

  async function GetReviews(){
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/asare/GetChat`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const responseData = response.data.data;
      setMessages(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const sendMessage = () => {
    if (ws && ws.readyState == 1) {
      const messageId = generateMessageId();
      const userImage = Cookies.get('V_Picture') && Cookies.get('V_Picture').startsWith('https://') ? Cookies.get('V_Picture') : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get('V_Picture')}`;
      const fileInput = document.getElementById('fileInput');
      const file = fileInput.files[0];
      const reader = new FileReader();
  
      reader.onload = function(event) {
        const fileData = event.target.result;
        let messageObject = {
          id: messageId,
          userId: Cookies.get('V_Id'),
          userImage: userImage,
          recieverName:'John Doe',
          chatId: '1',
          text: input
        };
  
        if (file) {
          messageObject.file = {
            name: file.name,
            type: file.type,
            size: file.size,
            data: fileData
          };
        }
  
        const messageString = JSON.stringify(messageObject);
        ws.send(messageString);
  
        sentMessages.set(messageId, { text: input, status: 'not read', file: file });
  
        setInput('');
      };
  
      reader.onerror = function(event) {
        console.error("File reading error:", event.target.error);
      };
  
      if (file) {
        reader.readAsDataURL(file); // Read the file as data URL
      } else {
        // If no file is selected, send the message without file details
        reader.onload({ target: { result: '' } });
      }
    } else {
      console.log('WebSocket not connected');
    }
  };
  
  
  return (
    <section>
      <Lognavbar/>
      <audio ref={audioRef} src="../Assets/notify.wav"/>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
            <h5 className="font-weight-bold mb-3 text-center text-lg-start" style={{color:"white"}}>Contacts</h5>
            <div className="card" style={{backgroundColor:"#1a2229"}}>
              <div className="card-body">
                <ul className="list-unstyled mb-0">
                  <li className="p-2 border-bottom" style={{ backgroundColor: '#000000' }}>
                    <a href="#!" className="d-flex justify-content-between">
                      <div className="d-flex flex-row">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-8.webp"
                          alt="avatar"
                          className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                          width="60"
                          height="60"
                        />
                        <div className="pt-1" style={{textAlign:"left"}}>
                          <p className="fw-bold mb-0" style={{color:"#ff4e00"}}>John Doe</p>
                          <p className="small text-muted">Hello, Are you there?</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="p-2 border-bottom" style={{ backgroundColor: '#000000' }}>
                    <a href="#!" className="d-flex justify-content-between">
                      <div className="d-flex flex-row">
                        <img
                          src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
                          alt="avatar"
                          className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                          width="60"
                          height="60"
                        />
                        <div className="pt-1" style={{textAlign:"left"}}>
                          <p className="fw-bold mb-0" style={{color:"#ff4e00"}}>John Doe</p>
                          <p className="small text-muted">Hello, Are you there?</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="p-2 border-bottom" style={{ backgroundColor: '#000000' }}>
                    <a href="#!" className="d-flex justify-content-between">
                      <div className="d-flex flex-row">
                        <img
                          src="https://t3.ftcdn.net/jpg/04/60/91/88/360_F_460918802_XVCymFr7MoziFpnInbTDvrlblYhvAOi2.jpg"
                          alt="avatar"
                          className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                          width="60"
                          height="60"
                        />
                        <div className="pt-1" style={{textAlign:"left"}}>
                          <p className="fw-bold mb-0" style={{color:"#ff4e00"}}>John Doe</p>
                          <p className="small text-muted">Hello, Are you there?</p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
          <ul class="list-unstyled">
          <li class="d-flex justify-content-between mb-4" style={{backgroundColor:"#1a2229",height:"400px",overflowY:"auto"}}>
            <div class="card" style={{backgroundColor:"#1a2229",width:"100%"}}>
              <div class="card-header d-flex p-3">
                <img src="https://t3.ftcdn.net/jpg/04/60/91/88/360_F_460918802_XVCymFr7MoziFpnInbTDvrlblYhvAOi2.jpg" alt="" style={{width:"40px",height:"40px",borderRadius:"50%"}}/>
                <div style={{marginLeft:"12px"}}>
                    <p class="fw-bold mb-0" style={{color:"#ff4e00"}}>Brad Pitt</p>
                    <p style={{fontSize:"12px",marginLeft:"-15px",color:"white"}}>10:02 PM</p>
                </div>
              </div>
              <div class="card-body">
                {messages.map((item,index)=>(
                  <MDBRow>
                    <MDBCol md={7}>
                      {item.userId==Cookies.get('V_Id')?(
                        <div class="mb-0" style={{textAlign:"left",display:"flex"}}>
                          <img src={item.userImage} alt="" style={{width:"40px",height:"40px",borderRadius:"50%"}}/>
                          <div style={{marginLeft:"15px"}}>
                              <p style={{color:"#ff4e00"}}>{item.userId==Cookies.get('Id') ? 'ME':item.recieverName}</p>
                              <p style={{color:"white",marginTop:"-17px",fontSize:"13px"}}>{item.text}</p>
                              {item.file?(
                                <div style={{width:"200px",height:"50px",borderRadius:"5px",padding:"10px",border:"1px solid white",marginTop:"-8px", display:"flex"}}>
                                  <a href={`${process.env.REACT_APP_BASE_URL}/files/${item.file}`}><i className='fa fa-file' style={{marginTop:"4px",color:"#ff4e00"}}></i> <span style={{marginLeft:"7px",marginTop:"4px",color:"white",fontSize:"12px"}}>{item.file.substr(0,25)}</span></a>
                                </div>
                              ):(
                                null
                              )}
                          </div>
                        </div>
                      ):(
                        ""
                      )}
                    </MDBCol>
                    <MDBCol md={5}>
                      {item.userId!=Cookies.get('V_Id')?(
                          <div class="mb-0" style={{textAlign:"left",display:"flex"}}>
                            <img src={item.userImage} alt="" style={{width:"40px",height:"40px",borderRadius:"50%"}}/>
                            <div style={{marginLeft:"15px"}}>
                                <p style={{color:"#ff4e00"}}>{item.userId==Cookies.get('Id') ? 'ME':item.recieverName}</p>
                                <p style={{color:"white",marginTop:"-17px",fontSize:"13px"}}>{item.text}</p>
                                {item.file?(
                                  <div style={{width:"200px",height:"50px",borderRadius:"5px",padding:"10px",border:"1px solid white",marginTop:"-8px", display:"flex"}}>
                                    <a href={`${process.env.REACT_APP_BASE_URL}/files/${item.file}`}><i className='fa fa-file' style={{marginTop:"4px",color:"#ff4e00"}}></i> <span style={{marginLeft:"7px",marginTop:"4px",color:"white",fontSize:"12px"}}>{item.file.substr(0,25)}</span></a>
                                  </div>
                                ):(
                                  null
                                )}
                            </div>
                          </div>
                        ):(
                          ""
                        )}
                    </MDBCol>
                  </MDBRow>
                ))}
              </div>
            </div>
          </li>
          <div style={{marginTop:"-17px"}}>
            <li>
                <div data-mdb-input-init class="form-outline">
                  <textarea 
                    class="form-control" 
                    id="textAreaExample2" 
                    rows="4" 
                    style={{backgroundColor:"#1a2229",color:"white",border:"1px solid white"}} 
                    value={input} 
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); 
                        sendMessage();
                      }
                    }}
                  ></textarea>
                <label class="form-label" for="textAreaExample2">Message</label>
                </div>
                <div class="form-outline" style={{marginTop:"5px",marginBottom:"5px"}}>
                  <input class="form-control" id="fileInput" type="file" style={{backgroundColor:"#1a2229",color:"white",border:"1px solid white"}}/>
                </div>
            </li>
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}}>Job Completed</MDBBtn>
              <MDBBtn style={{color:"white",backgroundColor:"#ff4e00"}} onClick={sendMessage}><i className="fa fa-send" style={{marginRight:"10px"}}></i>Send</MDBBtn>
            </div>
          </div>
        </ul>

      </div>

          </div>
        </div>
    </section>
  );
};

export default Chat;
