import React, { useState, useEffect } from "react";
import { MDBInput, MDBCol, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import Select from "react-select"; // If not used, you can remove this import
import axios from "axios";
import Footer from "./footer";
import Lognavbar from "../Components/lognavbar";
import Auth from "./auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  Auth();
  const [previous, setPrevious] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    document.body.style.backgroundColor = "black";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newpass !== confirm) {
      toast.error('New Password and confirm password must be the same');
    } else {
      const data = {
        previous: previous,
        newpass: newpass,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/admin/changepassword`,
          data
        );
        console.log(response);
        if (response.data.message === "success") {
          toast.success('Password Updated');
        } else if (response.data.message === "failure") {
          toast.error('Current password is not correct');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Error updating Password', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  return (
    <>
      <Lognavbar />
      <ToastContainer />
      <div className="container subadminform" style={{ marginTop: "-90px" }}>
        <div className="row w-100 justify-content-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <h2 style={{ color: "#ff4e00", marginTop: "2%" }} className="mb-4 text-center">
                Change Password
              </h2>
              <MDBCol>
                <MDBInput
                  id="current"
                  label="Current Password"
                  type="password"
                  value={previous}
                  style={{ marginTop: "10px" }}
                  onChange={(e) => setPrevious(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  id="new"
                  label="New Password"
                  type="password"
                  value={newpass}
                  style={{ marginTop: "10px" }}
                  onChange={(e) => setNewpass(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  id="confirm"
                  label="Confirm Password"
                  type="password"
                  value={confirm}
                  style={{ marginTop: "10px" }}
                  onChange={(e) => setConfirm(e.target.value)}
                />
              </MDBCol>
              <MDBBtn type="submit" className="mb-4" block style={{ marginTop: "10px" }}>
                Update Password
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
