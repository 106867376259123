import React, { useEffect, useState } from "react";
import "../App.css";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBInput,
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Select from "react-select";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import Auth from "./auth";
import axios from "axios";
const SeekersPackagesBought = () => {
  Auth();
  const [seekers, setseekers] = useState([]);
  const [filteredseekers, setFilteredseekers] = useState();
  const [jobdetails, setjobdetails] = useState([]);

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/packages/checkalljobdetails`,
        {}
      );
      setseekers(response.data);
      setFilteredseekers(response.data);
      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  

  console.log("upadted", seekers);

  useEffect(() => {
    fetchdata();
  }, []);
  const [selectedseeker, setSelectedseeker] = useState(null);
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = (seeker) => {
    setSelectedseeker(seeker);
    setBasicModal(!basicModal);
  };

  const [basicModal1, setBasicModal1] = useState(false);

  const toggleOpen1 = (seeker) => {
    setSelectedseeker(seeker);
    setBasicModal1(!basicModal1);
  };
  useEffect(() => {
    document.body.style.color = "white";

    document.body.style.backgroundColor = "black";
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterseekers(event.target.value);
  };

  const filterseekers = (query) => {
    const filtered =
      seekers &&
      seekers.filter((seeker) => {
        const packageNames = seeker.packageNames.map((packageName) =>
          packageName.toLowerCase()
        );

        // Check if any package name matches the query
        const packageMatch = packageNames.some((packageName) =>
          packageName.includes(query.toLowerCase())
        );

        const uniqueAssignedJobsLength =
          seeker.assignedJobs && Array.isArray(seeker.assignedJobs)
            ? new Set(seeker.assignedJobs).size
            : 0;

        const uniqueCompletedJobsLength = seeker.completedJobs && Array.isArray(seeker.completedJobs)
        ? new Set(seeker.completedJobs).size
        : 0;
        const uniqueCurrentJobsLength = seeker.currentJobs && Array.isArray(seeker.currentJobs)
        ? new Set(seeker.currentJobs).size
        : 0;

        return (
          seeker.firstName.toLowerCase().includes(query.toLowerCase()) ||
          seeker.lastName.toLowerCase().includes(query.toLowerCase()) ||
          seeker.email.toLowerCase().includes(query.toLowerCase()) ||
        
          seeker.gender.toLowerCase() === query.toLowerCase() ||
          seeker.verification.toString().includes(query) ||
          seeker.ratings.toString().includes(query) ||
          packageMatch ||
          uniqueAssignedJobsLength.toString().includes(query) ||
          uniqueCompletedJobsLength.toString().includes(query) ||
          uniqueCurrentJobsLength.toString().includes(query)
        );
      });

    setFilteredseekers(filtered);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#332D2D",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };
  return (
    <>
      <Lognavbar />
      <div>
        <h1 style={{ color: "#ff4e00" }}>Seekers Packages Bought</h1>

        <div className="">
          <div className="search  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by Name, Email, Creation-date"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />
          </div>

          <MDBTable
            style={{ width: "95%" }}
            className="mx-auto mt-3"
            responsive
          >
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th>Name</th>
                <th>Packages</th>

                <th>Pending</th>
                <th>Current</th>
                <th>Assigned</th>
                <th>Job History</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="text-white">
              {filteredseekers &&
                filteredseekers.map((seeker, index) => (
                  <tr key={seeker.seekersId}>
                    <td>
                      <div className="ms-3">
                        <p className="fw-bold mb-1">
                          {seeker.firstName} {seeker.lastName}
                        </p>
                        <p className="text-muted mb-0">{seeker.email}</p>
                      </div>
                    </td>
                    <td>
                      {seeker.packageNames && seeker.packageNames.length > 0
                        ? seeker.packageNames.map((packageName, index) => (
                            <span key={index}>
                              {packageName === null || packageName.length === 0
                                ? "none"
                                : packageName}
                              {index < seeker.packageNames.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))
                        : "none"}
                    </td>

                    <td>
                      {seeker.assignedJobs && Array.isArray(seeker.assignedJobs)
                        ? new Set(seeker.assignedJobs).size
                        : 0}
                    </td>
                    <td>
                      {seeker.currentJobs && Array.isArray(seeker.currentJobs)
                        ? new Set(seeker.currentJobs).size
                        : 0}
                    </td>
                    <td>
                      {seeker.assignedJobs && Array.isArray(seeker.assignedJobs)
                        ? new Set(seeker.assignedJobs).size
                        : 0}
                    </td>
                    <td>
                      {seeker.completedJobs &&
                      Array.isArray(seeker.completedJobs)
                        ? new Set(seeker.completedJobs).size
                        : 0}
                    </td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>
        </div>
        <MDBModal
          className="modalRegister"
          open={basicModal}
          onClose={() => setBasicModal(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Seekers Details</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {selectedseeker && (
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YXZhdGFyfGVufDB8fDB8fHww"
                        className="img-fluid w-75"
                        alt=""
                      />
                    </div>

                    <div className="col-md-8 text-start">
                      <p className="fw-bold mb-1">John Doe</p>
                      <p className="text-muted mb-0">John.doe@gmail.com</p>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Gender :{" "}
                        </span>
                        <span className=" fw-bold">Male</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          DOB :{" "}
                        </span>
                        <span className=" fw-bold">5/5/2024, 8:50 AM</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Mobile :
                        </span>
                        <span className="fw-bold">+92 0000000000</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Street 1 :{" "}
                        </span>
                        <span className="fw-bold">Street No 1</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 2 :{" "}
                        </span>
                        <span className=" fw-bold">Street No 2</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 3 :{" "}
                        </span>
                        <span className=" fw-bold">Street No 3</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          City :
                        </span>
                        <span className=" fw-bold">City</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25  fw-bolder"
                        >
                          Postcode:
                        </span>
                        <span className=" fw-bold">20233</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Country :
                        </span>
                        <span className="fw-bold">Country</span>
                      </div>

                      <div>
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder"
                        >
                          About
                        </span>
                        <p className=" fw-bold text-justify">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Harum ducimus doloremque modi maiores. Eum
                          laborum, obcaecati nostrum quisquam saepe architecto
                          totam voluptate mollitia reiciendis veritatis, porro
                          velit. Molestias, eius eum.
                        </p>
                      </div>

                      <MDBBtn className=" w-100 btn">Download CV</MDBBtn>
                    </div>
                  </div>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal
          className="modalRegister"
          open={basicModal1}
          onClose={() => setBasicModal1(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Edit Seeker</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen1}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <form>
                  <MDBInput
                    label="First Name"
                    id="firstName"
                    type="text"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Last Name"
                    id="lastName"
                    type="text"
                    className="mb-3"
                  />
                  <MDBInput
                    label=""
                    id="profilePicture"
                    type="file"
                    className="mb-3"
                  />

                  <Select
                    className="mb-3"
                    id="multi-select"
                    name="gender"
                    required
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                      { value: "Other", label: "Other" },
                    ]}
                    isMulti={false}
                    styles={customStyles}
                    placeholder="Select Answer"
                  />

                  <Select
                    className="mb-3"
                    id="multi-select"
                    name="gender"
                    required
                    options={[
                      { value: "student", label: "Student Accomodation" },
                      {
                        value: "nonstudent",
                        label: "Non-Student Accomodation",
                      },
                    ]}
                    isMulti={false}
                    styles={customStyles}
                    placeholder="Select Answer"
                  />

                  <MDBInput
                    label="Date of Birth"
                    id="dob"
                    type="date"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Email"
                    id="email"
                    type="email"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Mobile"
                    id="mobile"
                    type="tel"
                    className="mb-3"
                  />
                  <MDBInput
                    label="Password"
                    id="password"
                    type="password"
                    className="mb-3"
                  />
                </form>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen1}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>

      <Footer />
    </>
  );
};

export default SeekersPackagesBought;
