import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBModalDialog,
  MDBModalContent,
  MDBInput,
} from "mdb-react-ui-kit";
import axios from "axios";
import Lognavbar from "../Components/lognavbar";
import Footer from "./footer";
import Auth from "./auth";
import {toast,ToastContainer} from "react-toastify"

import Select from "react-select";
const AcceptedWithdrawalRequest = () => {
  Auth();
  const [viewers, setViewers] = useState([]);
  const [selectedViewer, setSelectedViewer] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const [selectedViewers, setSelectedViewers] = useState([]);
  const [selectedViewersChecked, setSelectedViewersChecked] = useState([]);
  const [basicModal1, setBasicModal1] = useState(false);
  const [filteredviewer, setviewer] = useState();
  const [allearnings, setallearnings] = useState();
  console.log(selectedViewersChecked);
  const toggleOpen = (viewer) => {
    setSelectedViewer(viewer);
    setBasicModal(!basicModal);
  };

  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getAllacceptedearnings`,
        {}
      );
      setViewers(response.data);
      setviewer(response.data);
      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchdata2 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getallearnings`,
        {}
      );
      setallearnings(response.data);
      console.log("get all Success eari:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchdata();
    fetchdata2();
  }, []);

  const toggleOpen1 = async (viewer) => {
    setSelectedViewer(viewer);

    setBasicModal1(!basicModal1);
  };

  useEffect(() => {
    document.body.style.color = "white";
    document.body.style.backgroundColor = "black";
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleDelete = async () => {
    console.log(selectedViewersChecked);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/delviewers`,
        {
          data: { viewerIds: selectedViewersChecked },
        }
      );

      console.log("Delete Success:", response.data);

      // Update local state after successful deletion
      const remainingViewers = viewers.filter(
        (viewer) => !selectedViewers.includes(viewer)
      );
      setViewers(remainingViewers);
      setSelectedViewers([]);
    } catch (error) {
      console.error("Delete Error:", error);
    }

    fetchdata();
  };

  const handleSelectAll = () => {
    if (selectedViewers.length > 0) {
      setSelectedViewers([]);
      setSelectedViewersChecked([]);
    } else {
      setSelectedViewers([...viewers]);
      setSelectedViewersChecked(viewers.map((viewer) => viewer.viewersId));
    }
  };

  const handleAssignPassword = async () => {
    console.log(selectedViewer);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/assignpasswordviewer`,
        selectedViewer
      );
      console.log("Success:", response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
    toggleOpen();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid white",
      color: "white",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      textAlign: "start",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  const [basicModal5, setBasicModal5] = useState(false);

  const toggleOpen5 = () => setBasicModal5(!basicModal5);

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption ? selectedOption.value : "");
  };

  const handleDateChange = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterviewer(event.target.value);
  };

  console.log("viewers", viewers);

  const filterviewer = (query) => {
    const filtered = viewers.filter((viewer) => {
      return (
        viewer.firstname.toLowerCase().includes(query.toLowerCase()) ||
        viewer.lastname.toLowerCase().includes(query.toLowerCase()) ||
        viewer.email.toLowerCase().includes(query.toLowerCase()) ||
        viewer.city.toLowerCase().includes(query.toLowerCase()) ||
        ((viewer.city.toLowerCase().includes(selectedCity.toLowerCase()) ||
          selectedCity === "") &&
          (viewer.createdAt === selectedDate || !selectedDate)) ||
        (viewer.availability === 1 ? "Yes" : "No")
          .toString()
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        viewer.ratings.toString().toLowerCase().includes(query.toLowerCase())
      );
    });
    setviewer(filtered);
  };

  const filterViewer = () => {
    const filtered = viewers.filter((viewer) => {
      console.log("I am in filtering ", selectedCity, selectedDate);

      const viewerDate = new Date(viewer.createdAt).toISOString().split("T")[0];

      console.log("viewerDate", viewerDate);
      const cityMatch =
        !selectedCity ||
        viewer.city.toLowerCase().includes(selectedCity.toLowerCase());
      const dateMatch = !selectedDate || viewerDate === selectedDate;

      return cityMatch && dateMatch;
    });

    setviewer(filtered);
    toggleOpen5();
  };

  const [page, setPage] = useState(1);
  const itemsPerPage = 50;

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  console.log("endinded", endIndex);
  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const sendexcel=async()=>{

   try {
    
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Admin/sendwithdraw`
     
    );

    console.log("success",response.data);

    toast.success("File Sent Successfully");


   } catch (error) {
    toast.error("Some Error Occured");

   }


  }

  return (
    <>
      <Lognavbar />

      <ToastContainer />
      <div>
        <h2 style={{ color: "#ff4e00" }}>Accepted Requests</h2>

        <div className="">
          <div className="search  ms-auto mr-4">
            <input
              className="form-control bg-black mt-3"
              label="Search"
              id="form1"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              style={{
                color: "#fff",
                border: "1px solid #ced4da",
                borderRadius: "5px",
              }}
            />

            <MDBBtn className="mt-3" onClick={toggleOpen5}>
              filter
            </MDBBtn>
          </div>

          <MDBModal
            className="modalRegister"
            open={basicModal5}
            onClose={() => {
              setBasicModal5(false);
            }}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent className="bg-dark">
                <MDBModalHeader>
                  <MDBModalTitle>Modal title</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleOpen5}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="mb-3">
                    <label htmlFor="citySelect" className="form-label">
                      City
                    </label>

                    <Select
                      options={viewers.map((viewer) => ({
                        value: viewer.city,
                        label: viewer.city,
                      }))}
                      styles={customStyles}
                      placeholder="Choose your city"
                      onChange={handleCityChange}
                    />
                    <label htmlFor="datePicker" className="form-label mt-3">
                      Date
                    </label>
                    <MDBInput
                      id="datePicker"
                      type="date"
                      name="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="form-control"
                    />
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={toggleOpen5}>
                    Close
                  </MDBBtn>
                  <MDBBtn onClick={filterViewer}>Save changes</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

          <MDBTable
            style={{ width: "95%" }}
            className="mx-auto mt-3"
            responsive
          >
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th>Name</th>
                <th>Accepted Amounts</th>
                <th>Total Earnings</th>

                <th>View Details</th>
                <th>Status</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="text-white">
              {allearnings &&
                allearnings &&
                filteredviewer &&
                filteredviewer
                  .slice(startIndex, endIndex)
                  .map((viewer, index) => {
                    const earning =
                      allearnings &&
                      allearnings.find((e) => e.viewersId === viewer.viewersId);

                    return (
                      <tr key={viewer.viewersId}>
                        <td>
                          <div className="ms-3">
                            <p className="fw-bold mb-1">
                              {viewer.firstname} {viewer.lastname}
                            </p>
                            <p className="text-muted mb-0">{viewer.email}</p>
                          </div>
                        </td>
                        <td>£ {viewer.EarnedAmount}</td>
                        <td>£ {earning ? earning.totalEarnings : "N/A"}</td>

                        <td>
                          <MDBBtn
                            color="info"
                            size="sm"
                            onClick={() => toggleOpen1(viewer)}
                          >
                            View Details
                          </MDBBtn>
                        </td>
                        <td>

                        <MDBBtn
                            className="mx-1 green"
                            color="success"
                            size="sm"
                           
                          >
                            Accepted
                          </MDBBtn>
                       
                        </td>
                      </tr>
                    );
                  })}
            </MDBTableBody>
          </MDBTable>


         { viewers&&viewers.length>0?
          <MDBBtn onClick={sendexcel}>Send Excel File</MDBBtn>:""}

          <div className="d-flex justify-content-around  my-3">
            <MDBBtn onClick={prevPage} disabled={page === 1}>
              Prev
            </MDBBtn>
            <MDBBtn
              onClick={nextPage}
              disabled={
                (endIndex >= filteredviewer && filteredviewer.length) ||
                (filteredviewer && filteredviewer.length < 50)
              }
            >
              Next
            </MDBBtn>
          </div>

          {/* {selectedViewersChecked.length !== 0 ? (
            <MDBBtn onClick={handleDelete}>Delete Selected</MDBBtn>
          ) : (
            ""
          )} */}

          {/* <MDBBtn className="ms-2" onClick={handleSelectAll}>
            {selectedViewersChecked.length === 0
              ? "Select All"
              : "UnSelect All"}
          </MDBBtn> */}
        </div>

        <MDBModal
          className="modalRegister"
          open={basicModal1}
          onClose={() => setBasicModal1(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent className="bg-dark">
              <MDBModalHeader>
                <MDBModalTitle>Viewer Details</MDBModalTitle>
                <MDBBtn
                  className="btn-close btn-white"
                  color="white"
                  onClick={toggleOpen1}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {selectedViewer && (
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/files/${selectedViewer.profilePicture}`}
                        className="img-fluid w-75"
                        alt=""
                      />
                    </div>

                    <div className="col-md-8 text-start">
                      <p className="fw-bold mb-1">
                        {selectedViewer.firstname} {selectedViewer.lastname}
                      </p>
                      <p className="text-muted mb-0">{selectedViewer.email}</p>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Gender :{" "}
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.gender}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          DOB :{" "}
                        </span>
                        <span className=" fw-bold">{selectedViewer.dob}</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Mobile :
                        </span>
                        <span className="fw-bold">{selectedViewer.mobile}</span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder w-25"
                        >
                          Street 1 :{" "}
                        </span>
                        <span className="fw-bold">
                          {selectedViewer.street1}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 2 :{" "}
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.street2}
                        </span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Street 3 :{" "}
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.street3}
                        </span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          City :
                        </span>
                        <span className=" fw-bold">{selectedViewer.city}</span>
                      </div>

                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25  fw-bolder"
                        >
                          Postcode:
                        </span>
                        <span className=" fw-bold">
                          {selectedViewer.postcode}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" w-25 fw-bolder"
                        >
                          Country :
                        </span>
                        <span className="fw-bold">
                          {selectedViewer.country}
                        </span>
                      </div>

                      <div>
                        <span
                          style={{ color: "#ff4e00" }}
                          className=" fw-bolder"
                        >
                          About
                        </span>
                        <p className=" fw-bold text-justify">
                          {selectedViewer.about}
                        </p>
                      </div>
                      <MDBBtn
                        download
                        onClick={() =>
                          (window.location.href = `${process.env.REACT_APP_BASE_URL}/files/${selectedViewer.CV}`)
                        }
                        className="w-100 btn"
                      >
                        Download CV
                      </MDBBtn>
                    </div>
                  </div>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen1}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <Footer />
      </div>
    </>
  );
};

export default AcceptedWithdrawalRequest;
