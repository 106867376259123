import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
} from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";
import Auth from "./auth";
import Lognavbar from "../Components/lognavbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
export default function Earningdetails() {
  Auth();
  const [basicModal, setBasicModal] = useState(false);
  const [earningdetails, setearningdetails] = useState("");
  const [weekly, setweekly] = useState("");

  const toggleOpen = () => setBasicModal(!basicModal);
  const { viewerId } = useParams();
  const [totalearnings, settotalearnings] = useState();
  const [paid,setpaid]=useState('')

  const TotalPaid = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/TotalPaid`,
        {
          params: {
            viewersId: Cookies.get("V_Id"),
          },
        }
      );

      console.log(response.data[0]);
      setpaid(response.data[0]);
      

     
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Admin/getallearningsbyId`,
        {
          params: {
            viewersId: viewerId,
          },
        }
      );

      setearningdetails(response.data);

      const sum = response.data.reduce(
        (total, item) => total + item.EarnedAmount,
        0
      );

      settotalearnings(sum);

      console.log("sum", sum);

      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      const filteredEarnings = response.data.filter((earning) => {
        const earningDate = new Date(earning.earningDate);
        return earningDate >= sevenDaysAgo && earningDate <= today;
      });

      const thisweek =
        filteredEarnings &&
        filteredEarnings.reduce((total, item) => total + item.EarnedAmount, 0);

      setweekly(thisweek);
      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchdata();

    TotalPaid();

    document.body.style.backgroundColor = "#000000";
  }, []);

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }

  return (
    <div className="about">
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          Earnings
        </h1>
        <center>
          <MDBRow
            className="row-cols-1 row-cols-md-4 g-4"
            style={{ width: "80%" }}
          >
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Jobs Done
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    {earningdetails && earningdetails.length}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Earning
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>
                    £ {totalearnings && totalearnings}
                  </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    Total Paid
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>£ {paid?paid.totalEarnings:"0"}</h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol>
              <MDBCard className="h-100" style={{ backgroundColor: "#1a2229" }}>
                <MDBCardBody>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    This Week Earning
                  </MDBCardTitle>
                  <h4 style={{ color: "#ff4e00" }}>£ {weekly && weekly} </h4>
                  <MDBCardText></MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </center>

        <MDBModal
          open={basicModal}
          onClose={() => setBasicModal(false)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent style={{ backgroundColor: "black" }}>
              <MDBModalHeader>
                <MDBModalTitle style={{ color: "#ff4e00" }}>
                  Withdraw
                </MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="mb-4" style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="form1"
                    style={{
                      textAlign: "left",
                      display: "block",
                      color: "white",
                    }}
                  >
                    Amount
                  </label>
                  <MDBInput
                    size="lg"
                    id="form1"
                    type="number"
                    style={{ borderRadius: "0" }}
                  />
                </div>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Close
                </MDBBtn>
                <MDBBtn style={{ backgroundColor: "#ff4e00", color: "white" }}>
                  Submit
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <div
          style={{ textAlign: "left", marginLeft: "60px", marginTop: "20px" }}
        >
          <h4 style={{ color: "white" }}>Earnings History</h4>
          <MDBTable style={{ width: "95%" }}>
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th scope="col">Job Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Type</th>
                <th scope="col">Dated</th>
                
              </tr>
            </MDBTableHead>
            <MDBTableBody style={{ color: "white" }}>

          {  earningdetails&&earningdetails.map((earn)=>{
              return(
                <tr>
                <td>{earn.packagename}</td>
                <td>{earn.EarnedAmount}</td>
                <td>{earn.status===null?"Earning":earn.status===0?"Pending":earn.status===1?"Withdrawn":""}</td>
              

                <td>{convertToUKTime(earn.earningDate)}</td>


              </tr>
              )
            })}
             
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>
      <Footer />
    </div>
  );
}
